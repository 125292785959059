import NetUtils from "../../utils/NetUtils";

const ProjectService = {
    generateRentForAllCompanies: () => NetUtils.doGet('/api/projects/generate-rent'),
    generateRent: id => NetUtils.doGet('/api/projects/' + id + '/generate-rent'),
    findRentsByTender: tenderId => NetUtils.doGet('/api/projectrents', {tender: tenderId}),
    findRentsByProject: projectId => NetUtils.doGet('/api/projectrents', {project: projectId}),

    count: projectId => NetUtils.doGet('/api/projectrents/' + projectId + '/count'),

    update: model => NetUtils.doPost('/api/projectrents', model),
    delete: model => NetUtils.doDelete('/api/projectrents', model),
    deleteBatch: idList => NetUtils.doDelete('/api/projectrents/batch', idList),
}
export default ProjectService;
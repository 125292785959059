import React, {useState} from "react";
import PdfViewer from "../../components/Appendix/PdfViewer";
import {CancelButton, PdfButton, SaveButton} from "../../components/Buttons/Button";
import {confirm} from "../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Spinner from "../../components/Spinner/Spinner";
import useMountEffect from "../../hooks/useMountEffect";
import {i18n, initI18n} from "../../I18n/I18n";
import TenderStatus from "../../modules/Tenders/TenderStatus";
import {formatTime} from "../../utils/DateUtils";
import NetUtils from "../../utils/NetUtils";
import './TenderAnswer.scss';

export const TenderAnswer = props => {
    const token = props.token
    const [model, setModel] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [failedToLoad, setFailedToLoad] = useState(false);
    const answered = model?.answerTime !== undefined

    useMountEffect(async () => {
        showLoading()
        await initI18n()
        const _model = await NetUtils.doGet('/api/external/tender-answer/' + token)
        setModel(_model)
        hideLoading()
    })

    if (!model) return <Spinner/>

    function answer(accepted) {
        showLoading()
        const _answer = {
            accepted: accepted
        }
        NetUtils.doPost('/api/external/tender-answer/' + token + '/answer', _answer).then(_model => {
            if (_model) {
                setModel(_model)
                hideLoading()
            }
        })
    }

    return <div id='App'>
        <div className='TenderAnswer'>
            {
                loaded && answered &&
                <header>
                    {model.changeOrder ? 'ÄTA:n' : 'Offerten'} {model.accepted ? 'accepterad' : 'nekad'} {formatTime(model.answerTime)}<br/>
                    från IP-adress {model.answerIp}
                </header>
            }

            {
                loaded && !model.answerable && !answered &&
                <header>
                    Offerten är manuellt besvarad ({TenderStatus.getName(model.status)})
                </header>
            }
            {
                loaded && model.answerable && !answered &&
                <header>
                    <div className='label'>
                        {'Hur vill du gå vidare med ' + (model.changeOrder ? 'ÄTA:n' : 'offerten') + '?'}
                    </div>

                    <div className='answer-buttons'>
                        <SaveButton labelI18n='accept' onClick={e => {
                            confirm({
                                header: i18n('question'),
                                message: 'Är du säker på att du vill acceptera ' + (model.changeOrder ? 'ÄTA:n' : 'offerten') + ' och lägga en beställning?',
                                options: [
                                    {
                                        label: i18n('yes'),
                                        onClick: () => answer(true)
                                    },
                                ]
                            })
                        }}/>
                        <CancelButton labelI18n='deny' onClick={e => {
                            confirm({
                                header: i18n('question'),
                                message: 'Är du säker på att du vill neka ' + (model.changeOrder ? 'ÄTA:n' : 'offerten') + '?',
                                options: [
                                    {
                                        label: i18n('yes'),
                                        onClick: () => answer(false)
                                    },
                                ]
                            })
                        }}/>
                    </div>
                </header>
            }
            <div className="document-container">
                {
                    !loaded &&
                    <div className='loading-image'>
                        <div>Laddar...</div>
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                    </div>
                }

                {
                    loaded &&
                    <div className='download-button-container'>
                        <PdfButton labelI18n='download' onClick={() =>
                            NetUtils.downloadFile('/api/external/tender-answer/' + token + '/pdf')
                        }/>
                    </div>
                }

                {/*<img src={'/api/external/tender-answer/' + token + '/image'} alt=''*/}
                {/*     onLoad={() => setLoaded(true)}*/}
                {/*     onError={e => {*/}
                {/*         setLoaded(true)*/}
                {/*         setFailedToLoad(true)*/}
                {/*         console.log("FAILED TO LOAD", e)*/}
                {/*     }}*/}
                {/*/>*/}
                <div className='pdf-viewer-container'>
                    <PdfViewer url={'/api/external/tender-answer/' + token + '/pdf'}
                               enableZoom={false}
                               onLoadSuccess={e => {
                                   setLoaded(true)
                               }}
                    />
                </div>
                {
                    failedToLoad &&
                    <div>
                        {'Det gick inte att visa ' + (model.changeOrder ? 'ÄTA:n' : 'offerten') + '. Ladda ner den istället.'}
                    </div>
                }
            </div>
        </div>
    </div>
}

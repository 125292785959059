import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {groupBy} from "lodash";
import React, {useContext, useMemo} from 'react';
import AppContext from "../../AppContext";
import {Credentials, LocalStorageCredentials, SessionStorageCredentials} from "../../Auth";
import AccessType from "../../enums/AccessType";
import {i18n} from "../../I18n/I18n";
import {STORAGE_PREFIX} from "../../utils/LocalStorageUtils";
import {deepCopy} from "../../utils/ObjectUtils";
import {MainButton} from "../Buttons/Button";
import Grid from "../Grid/Grid";
import XlnzDialog from "../XlnzDialog/XlnzDialog";

const CompanySwitch = props => {
    const context = useContext(AppContext);

    const {
        onHide
    } = props

    const companyList = useMemo(() => {
        const result = deepCopy(context.companies)
        for (const c of result) {
            c.accessTypeName = AccessType.getName(c.accessType)
            if (c.accessName) c.accessTypeName += ' - ' + c.accessName
        }
        return result
    }, [context.companies])

    let width = 450
    const accessTypes = groupBy(companyList, 'accessType')
    const hasMultipleaccessTypes = Object.keys(accessTypes).length > 1
    if (hasMultipleaccessTypes) width += 300


    const switchCompany = (company, newWindow) => {
        sessionStorage.removeItem(STORAGE_PREFIX + 'filter')

        const credentials = new Credentials(
            company.id,
            company.accessType,
            company.accessId
        )

        const localStorageCredentials = new LocalStorageCredentials()
        localStorageCredentials.save(credentials)
        // LocalStorageUtils.setItem('company', company.id)
        // LocalStorageUtils.setItem('companyAccessType', company.accessType)
        // LocalStorageUtils.setItem('companyAccessId', company.accessId)

        if (newWindow) {
            const newTab = window.open('/', '_blank')
            newTab.onload = () => {
                newTab.sessionStorage.setItem(STORAGE_PREFIX + 'company', company.id)
                newTab.sessionStorage.setItem(STORAGE_PREFIX + 'companyAccessType', company.accessType)
                newTab.sessionStorage.setItem(STORAGE_PREFIX + 'companyAccessId', company.accessId)
                newTab.location.reload()
            }
            onHide()
        } else {
            // SessionStorageUtils.setItem('company', company.id)
            // SessionStorageUtils.setItem('companyAccessType', company.accessType)
            // SessionStorageUtils.setItem('companyAccessId', company.accessId)
            const sessionStorageCredentials = new SessionStorageCredentials()
            sessionStorageCredentials.save(credentials)
            window.location = '/'
        }
    }

    const columns = [
        {headerI18n: 'company', field: 'label'},
        {headerI18n: 'access_as', field: 'accessTypeName', width: 300, mobile: true, hidden: !hasMultipleaccessTypes},
        {header: 'ID', field: 'id', width: 40, align: 'right', mobile: true, hidden: !context.user.dev},
        {
            headerI18n: 'open_in_a_new_window',
            field: 'open_in_a_new_window',
            body: rowData => <div style={{width: '100%'}}
                                  onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      switchCompany(rowData, true)
                                  }}>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{fontSize: '16px', color: '#666'}}/>
            </div>,
            width: 80,
            align: 'center'
        }
    ].filter(item => !item.hidden)

    return <XlnzDialog header={i18n('change_company')}
                       onHide={onHide}
                       buttons={() => [<MainButton key='close' labelI18n='close' onClick={e => props.onHide()}/>]}
    >
        {
            <div style={{width: width + 'px'}}>
                <Grid columns={columns}
                      value={companyList}
                      heightOffset={200}
                      onRowSelect={e => {
                          switchCompany(e.data)
                      }}
                />
            </div>
        }
    </XlnzDialog>
}
export default CompanySwitch;
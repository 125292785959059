import React, {useCallback, useMemo} from 'react';
import {useWatch} from "react-hook-form";
import Country from "../../enums/Country";
import useLoadCompanyInfo from "../../hooks/useLoadCompanyInfo";
import {
    validateSwedishRegNo,
    validateSwedishRegNoChecksum,
    validateSwedishRegNoFormat
} from "../../utils/ValidateUtils";
import FormItemText from './FormItemText'; // Anta att denna komponent finns

const FormItemRegNo = props => {
    const {
        name = 'regNo',
        country,
        form,
        required = true
    } = props

    const loadCompanyInfo = useLoadCompanyInfo()
    const {control} = form

    const watchRegNo = useWatch({control, name: name})

    const isSwedishCompany = useCallback(() => {
        const type = form.getValues('type');
        return type === 'COMPANY' && country === Country.SE.id;
    }, [country, form])

    const formatSwedishOrgNumber = (value) => {
        if (country === 'SE' && value.length === 10 && !value.includes('-')) {
            return value.slice(0, 6) + '-' + value.slice(6);
        }
        if (country === 'SE' && value.length === 12) {
            return value.slice(0, 8) + '-' + value.slice(8);
        }
        return value;
    };

    async function findCompanyInfoByRegNo(_regNo) {
        const name = form.getValues('name');
        if (_regNo.length === 11
            && isSwedishCompany()
            && validateSwedishRegNo(_regNo)
            && name.length === 0) {
            const _companyInfo = await loadCompanyInfo.findByRegNo(_regNo)
            if (!_companyInfo) return
            form.setValue('regNo', _companyInfo.regNo)
            form.setValue('name', _companyInfo.name)
            form.setValue('vatNo', _companyInfo.vatNo)
            form.setValue('address', _companyInfo.address)
            if (_companyInfo.email) form.setValue('email', _companyInfo.email)
        }
    }

    const handleBlur = (e) => {
        const formattedValue = formatSwedishOrgNumber(e.target.value);
        form.setValue('regNo', formattedValue);
        findCompanyInfoByRegNo(formattedValue);
    };

    const companyType = useMemo(() => {
        const _regNo = watchRegNo || ''
        // console.log("_regNo", _regNo, _regNo.length, isSwedishCompany())

        if (!isSwedishCompany()) return ''

        if (_regNo.length === 13) return "Enskild näringsverksamhet"

        if (!validateSwedishRegNo(_regNo)) return ''

        // Hämta första siffran i organisationsnumret (gruppnummer)
        const groupNumber = parseInt(_regNo[0], 10);

        // Identifiera företagsform baserat på gruppnummer
        switch (groupNumber) {
            case 1:
                return 'Dödsbo';
            case 2:
                return 'Stat, region, kommun eller församling';
            case 3:
                return 'Utländskt företag';
            case 5:
                return 'Aktiebolag';
            case 7:
                return 'Ekonomisk förening, bostadsrättsförening eller samfällighetsförening';
            case 8:
                return 'Ideell förening eller stiftelse';
            case 9:
                return 'Handelsbolag eller kommanditbolag';
            default:
                return 'Okänd företagsform';
        }
    }, [watchRegNo, isSwedishCompany])


    const validation = {
        validate: (value) => {
            if (isSwedishCompany() && required) {
                if (!validateSwedishRegNoFormat(value)) return 'Ogiltigt svenskt organisationsnummer'
                if (!validateSwedishRegNoChecksum(value)) return 'Ogiltig kontrollsiffra'
            }
            return undefined;
        }
    };

    return <div>
        <FormItemText
            key={name}
            name={name}
            labelI18n='reg_no'
            maxLength={32}
            width={150}
            onBlur={handleBlur}
            required={required}
            validation={validation}
            {...form}
        />
        <span style={{marginLeft: '7px', color: '#666', fontStyle: 'italic'}}>{companyType}</span>
    </div>
};

export default FormItemRegNo;


import CustomerQnas from "./Qnas";

export const modulesCustomer = [
    CustomerQnas
];

export const registerModulesCustomer = [];

export const settingModulesCustomer = [];

export const allCustomerModules = [...modulesCustomer, ...registerModulesCustomer, ...settingModulesCustomer]

export function getModuleCustomer(path) {
    path = path.split("?")[0];
    let m = allCustomerModules.find(m => m.routeProps.path === path);
    if (!m) {
        m = allCustomerModules
            .filter(m => m.routeProps.items?.length > 0)
            .flatMap(m => m.routeProps.items)
            .find(i => i.path === path);
    }
    return m;
}
import React, {useContext, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import AppContext from "../../../AppContext";
import Button, {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemAccount from "../../../components/EditForm/FormItemAccount";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import {useFilter} from "../../../components/Grid/filter";
import {errorAlert, infoAlert} from "../../../components/Overlay/Alert";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import TabPanel from "../../../components/TabView/TabPanel";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Company from "../../../enums/Company";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import Unit from "../../../enums/Unit";
import useDialog from "../../../hooks/useDialog";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import {findByField} from "../../../utils/ArrayUtil";
import {todayDate} from "../../../utils/DateUtils";
import ArticleService from "../ArticleService";
import ArticlepriceGrid from "./ArticlepriceGrid";

const ArticleEditForm = () => {
    const context = useContext(AppContext)
    const preload = usePreload()
    const articleList = preload.getArticleList();
    const dialog = useDialog()
    const userPersmissions = useUserPersmissions()

    const [form, setForm] = useState()

    const preSaveFunction = data => {
        if (!data.unit) delete data.unit
        if (!data.price) data.price = 0

        const a = findByField(articleList, 'number', data.number)
        if (a !== undefined && a.id !== data.id) {
            return i18n('error_artno_already_exists')
        }
    }


    async function save() {
        const isOk = await form.trigger()
        if (isOk) {
            showLoading()
            const data = form.getValues()
            const errorMsg = preSaveFunction(data)
            if (errorMsg) {
                errorAlert(errorMsg)
                hideLoading()
                return
            }
            const _resp = await ArticleService.update(data)
            form.reset(_resp)
            hideLoading()
            return _resp
        }
    }


    return <>
        <SimpleEditForm
            writeRole={Role.ARTICLE_WRITE}
            headlineI18n='article'
            defaultValues={{
                unit: undefined,
                accountId: undefined,
                name: '',
                number: '',
                price: 0,
                active: true,
                priceList: []
            }}
            onFormCreated={form => setForm(form)}
            loadFunction={ArticleService.findById}
            preSaveFunction={preSaveFunction}
            saveFunction={ArticleService.update}
            initRightButtons={() => [
                userPersmissions.hasRole(Role.ADMIN) &&
                (userPersmissions.hasRole(Role.SUPERADMIN) || context.currentCompany.id === Company.ENTER_UDDEVALLA_BOREALIS) && //REMOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOVE!
                <Button key='bulk_update' labelI18n='bulk_update' faicon={Icon.BULK_UPDATE}
                        onClick={async () => {
                            const _model = await save()
                            if (_model?.id > 0) dialog.show(<BulkUpdateDialog articleId={_model.id}/>);
                        }}/>
            ]}
            initLeftFields={form => [
                <FormItemText key='name' name='name' label={i18n('name')} maxLength={128} required {...form}/>,
                <FormItemText key='number' name='number' label={i18n('number')} maxLength={128} required {...form}/>,
                <FormItemDropdown key='unit' name='unit' label={i18n('unit')}
                                  options={Unit.values()}
                                  showSelect
                                  width={160}
                                  {...form} />,
                <FormItemAccount key='account' name='accountId' {...form} />,
                <FormItemCheckbox key='employeeExposed' name='employeeExposed'
                                  label={i18n('show_to_employees')} {...form}/>,
                <FormItemCheckbox key='active' name='active' label={i18n('active')} {...form}/>
            ]}
            initTabs={form => [
                <TabPanel key='price' header={i18n('prices')}>
                    <div className="formItem">
                        <Controller name='priceList'
                                    control={form.control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <ArticlepriceGrid
                                            inputRef={ref} value={value} disabled={form.disabled} onChange={onChange}
                                        />}/>
                    </div>
                </TabPanel>,
                (context.currentCompany.id === Company.PBAB || context.user.dev) &&
                <TabPanel key='specification' headerI18n='specification'>
                    <FormItemTextArea name='specification' {...form}/>
                </TabPanel>
            ]}
            preloadType={PreloadType.ARTICLE_LIST}
        />
        {dialog.render()}
    </>
}

export default ArticleEditForm;


function BulkUpdateDialog(props) {
    const {
        articleId
    } = props

    const filter = useFilter('BulkUpdateDialog', [
        {name: 'startDate', defaultValue: todayDate()},
        {name: 'endDate', defaultValue: undefined},
        {name: 'updateTenderrent', defaultValue: false},
        {name: 'updateProjectrentalbasis', defaultValue: false},
        {name: 'updateProjectrent', defaultValue: false},
        {name: 'updateTimeregarticle', defaultValue: false},
    ])

    const form = {
        ...useForm({
            defaultValues: {
                startDate: filter.startDate,
                endDate: filter.endDate,
                updateTenderrent: filter.updateTenderrent,
                updateProjectrentalbasis: filter.updateProjectrentalbasis,
                updateProjectrent: filter.updateProjectrent,
                updateTimeregarticle: filter.updateTimeregarticle
            }
        })
    }
    const {handleSubmit} = form

    const onSubmit = data => {
        confirmYes(async () => {
            filter.update('startDate', data.startDate)
            filter.update('endDate', data.endDate)
            filter.update('updateTenderrent', data.updateTenderrent)
            filter.update('updateProjectrentalbasis', data.updateProjectrentalbasis)
            filter.update('updateProjectrent', data.updateProjectrent)
            filter.update('updateTimeregarticle', data.updateTimeregarticle)

            console.log("DATA", articleId, data)

            if (
                data.updateTenderrent ||
                data.updateProjectrentalbasis ||
                data.updateProjectrent ||
                data.updateTimeregarticle
            ) {
                showLoading()
                const _resp = await ArticleService.bulkUpdatePrices(
                    articleId,
                    data.startDate,
                    data.endDate,
                    data.updateTenderrent,
                    data.updateProjectrentalbasis,
                    data.updateProjectrent,
                    data.updateTimeregarticle
                )

                let msg = ''
                if (data.updateTenderrent) msg += i18n('rental_basis') + ' - ' + i18n('tender') + ': ' + _resp.tenderrent + "\n"
                if (data.updateProjectrentalbasis) msg += i18n('rental_basis') + ' - ' + i18n('project') + ': ' + _resp.projectrentalbasis + "\n"
                if (data.updateProjectrent) msg += i18n('rents') + ': ' + _resp.projectrent + "\n"
                if (data.updateTimeregarticle) msg += i18n('time_registration') + ': ' + _resp.timeregarticle + "\n"

                infoAlert(msg)
                hideLoading()
            }
        })
    }

    return <XlnzDialog headerI18n='bulk_update'
                       onHide={props.onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={handleSubmit(onSubmit)}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div className='EditForm' style={{width: '400px', margin: 0}}>
            <div style={{color: 'red'}}>
                <b>Varning!</b> Den här åtgärden kommer att påverka ett stort antal rader.
                Det kan ta en stund att slutföra och kan inte ångras.<br/>

                Om en prislista är angiven på projektet kommer dess priser att användas.<br/>
                <br/>
                Priset som används är artikelpriset vid slutdatumet. Om inget slutdatum är angivet,
                används det senaste tillgängliga priset.
            </div>
            <form>
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                    <FormItemDate name='startDate' labelI18n='start_date' {...form} />
                    <FormItemOptionalDate name='endDate' labelI18n='end_date' {...form} />
                </div>
                <FormItemCheckbox name='updateTenderrent'
                                  label={i18n('rental_basis') + ' - ' + i18n('tender') + ' (' + i18n('all') + ')'} {...form} />
                <FormItemCheckbox name='updateProjectrentalbasis'
                                  label={i18n('rental_basis') + ' - ' + i18n('project') + ' (' + i18n('all') + ')'} {...form} />
                <FormItemCheckbox name='updateProjectrent' labelI18n='rents' {...form} />
                <FormItemCheckbox name='updateTimeregarticle' labelI18n='time_registration' {...form} />
            </form>
        </div>

    </XlnzDialog>
}
import {useContext} from "react";
import AppContext from "../AppContext";
import Company from "../enums/Company";

export default function useBetaQna() {
    const context = useContext(AppContext)
    return context.user.dev
        || context.currentCompany.id === Company.DEMOBOLAGET
        || context.currentCompany.id === Company.MOBILBYGG_ENTREPRENAD
        || context.currentCompany.id === Company.REELS_PROJEKT
}
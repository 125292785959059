import React, {useState} from 'react';
import {HashRouter as Router, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import './App.scss';
import AppContext from "./AppContext";
import Auth from "./Auth";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";
import {InitSpinner} from "./components/Spinner/Spinner";
import Currency from "./enums/Currency";
import Language from "./enums/Language";
import {uuidv4} from "./functions/makeId";
import useMountEffect from "./hooks/useMountEffect";
import usePreload from "./hooks/usePreload";
import useRoles from "./hooks/useRoles";
import useSecurity from "./hooks/useSecurity";
import useUserPersmissions from "./hooks/useUserPersmissions";
import {initI18n} from "./I18n/I18n";
import {DISPATCH_OBJECT} from "./index";
import {allModules, modules} from "./modules/modules";
import {allCustomerModules, modulesCustomer} from "./modulesCustomer/modules";
import {allSupplierModules, modulesSupplier} from "./modulesSupplier/modules";
import LocalStorageUtils from "./utils/LocalStorageUtils";
import NetUtils from "./utils/NetUtils";

export const APP_NAME = 'Verktyg1'
export const NBSP = '\u00A0'

export let COMPANY
export let CURRENCY

function initDeviceId() {
    if (!LocalStorageUtils.getItem("deviceId")) {
        LocalStorageUtils.setItem("deviceId", uuidv4())
    }
}

initDeviceId()


const App = () => {
    const [state, setState] = useState({
        initialized: false,
        user: undefined,
        companies: undefined,
        currentCompany: undefined
    })

    const security = useSecurity()
    const userPersmissions = useUserPersmissions()
    const roles = useRoles('App')
    const preload = usePreload()

    const signIn = async (loginInfo) => {
        // let user
        // let currentCompany
        //
        // if (loginInfo.companyList.length === 0) {
        //     alert("No company access.")
        //     security.signOut('no-company-access');
        // }
        //
        // loginInfo.companyList.forEach(c => c.type = 'COMPANY');
        // const companies = loginInfo.companyList
        //
        // let companyId = Number(SessionStorageUtils.getItem('company', 0))
        // let companyAccessType = SessionStorageUtils.getItem('companyAccessType', CompanyAccessType.USER.id)
        // let companyAccessId = SessionStorageUtils.getItem('companyAccessId', 0)
        // if (companyId === 0) {
        //     companyId = Number(LocalStorageUtils.getItem('company', 0))
        //     companyAccessType = LocalStorageUtils.getItem('companyAccessType', CompanyAccessType.USER.id)
        //     companyAccessId = LocalStorageUtils.getItem('companyAccessId', 0)
        // }
        // if (companyId > 0) {
        //     currentCompany = companies.find(r => r.id === companyId && r.accessType === companyAccessType)
        // }
        //
        // function getDefaultCompany(loginInfo) {
        //     loginInfo.companyList.forEach(c => {
        //         c.priority = Number(c.propList.find(p => p.name === Companyprop.PRIORITY.name)?.value) || 0
        //     })
        //     const cList = [...loginInfo.companyList]
        //     sortByField(cList, 'priority').reverse()
        //     return cList[0]
        // }
        //
        // if (!currentCompany && loginInfo.companyList.length > 0) currentCompany = getDefaultCompany(loginInfo)
        //
        // switch (currentCompany.accessType) {
        //     case CompanyAccessType.USER.id:
        //         user = loginInfo.user
        //         user.superAdmin = user.id === 1;
        //         break;
        //     case CompanyAccessType.CUSTOMERCONTACT.id:
        //         user = findById(loginInfo.customercontactList, companyAccessId)
        //         break;
        //     case CompanyAccessType.SUPPLIERCONTACT.id:
        //         user = findById(loginInfo.suppliercontactList, companyAccessId)
        //         break;
        // }
        //
        // console.log("currentCompany", currentCompany)
        //
        // LocalStorageUtils.setItem("company", currentCompany.id)
        // SessionStorageUtils.setItem("company", currentCompany.id)
        // LocalStorageUtils.setItem("companyAccessType", companyAccessType)
        // SessionStorageUtils.setItem("companyAccessType", companyAccessType)
        // LocalStorageUtils.setItem("companyAccessId", companyAccessId)
        // SessionStorageUtils.setItem("companyAccessId", companyAccessId)

        if (loginInfo.companyList.length === 0) {
            alert("No company access.")
            security.signOut('no-company-access');
        }

        const {
            user,
            companies,
            currentCompany,
            companyAccessType
        } = new Auth(loginInfo).evaluate

        const userPermissions = await NetUtils.doGet('/api/login/permissions')
        userPersmissions.set(userPermissions)
        roles.set(userPermissions.roleList)
        if (userPermissions.restrictPathList.length > 0) console.info("RESTRICT PATHS:", userPermissions.restrictPathList.join(", "));
        await preload.loadAll()

        console.info(companyAccessType + ": " + user?.name + ' (' + user?.id + ')')
        console.info("ROLES:", roles.getAll().join(", "))
        console.info("SELECTED COMPANY:", currentCompany.name + ' (' + currentCompany.id + ')')

        document.title = APP_NAME + ' - ' + currentCompany.name;

        COMPANY = currentCompany
        CURRENCY = Currency.findById(currentCompany.currency)

        setState(Object.assign({}, state, {
            initialized: true,
            user: user,
            companies: companies,
            currentCompany: currentCompany
        }))
    }

    async function getLoginInfo() {
        return await fetch('/api/login/info', {
            method: 'GET',
        }).then(res => {
            // console.log("res", res)
            if (res.ok) {
                return res.json()
            } else {
                security.signOut('no-company-access')
                return false
            }
        }, (e) => {
            console.error("Login Info:", e)
            return false
        });

    }

    useMountEffect(async () => {
        window.SERVER_URL = ''
        security.init()

        const loginInfo = await getLoginInfo()
        if (loginInfo) {
            // console.log("USER:", loginInfo?.user?.language)
            await initI18n(loginInfo?.user?.language || Language.SWEDISH.id)
            await signIn(loginInfo);
        } else {
            await initI18n()
            LocalStorageUtils.removeItem("company");
            LocalStorageUtils.removeItem("companyAccessType");
            setState({initialized: true})
        }
    })

    const isModuleAccessible = m => {
        if (m.roles && m.roles[0] === undefined) console.log("Invalid module roles", m)
        return (!m.roles || userPersmissions.hasRoles(m.roles)) &&
            userPersmissions.hasRestrictPath(m.routeProps.path)
    }

    const getDefaultRoute = () => {
        const defaultModule = [...modules, ...modulesCustomer, ...modulesSupplier].find(m => {
                return userPersmissions.hasRoles(m.roles) &&
                    userPersmissions.hasRestrictPath(m.routeProps.path)
            }
        )
        return defaultModule?.routeProps.path ?? ''
    }

    let view;
    if (!state.user && state.initialized) {
        return <div className="AppContainer">
            <div id='App' className="App"><Login/></div>
        </div>
    } else if (state.user && state.initialized && !state.companies) {
        view = <h1>Du har inte tillgång till några företag</h1>
    } else if (!state.initialized) {
        view = <InitSpinner/>
    } else if (state.user && state.companies && state.companies.length > 0) {
        view = <Router>
            <div className="AppContainer">
                <div id='App' className="App">
                    <div className="container">
                        <Header/>
                        <div className="content" style={{
                            // Not working when resizing dayplanning. Dont know why this is needed? 2022-11-08
                            //height: window.innerHeight - 64 - 0
                        }}>
                            <Routes>
                                {[...allModules, ...allCustomerModules, ...allSupplierModules]
                                    .filter(m => isModuleAccessible(m))
                                    .map(m => <Route {...m.routeProps} key={m.routeProps.path}/>)}

                                {[...allModules, ...allCustomerModules, ...allSupplierModules]
                                    .filter(m => isModuleAccessible(m))
                                    .filter(m => m.routeProps.items?.length > 0)
                                    .flatMap(m => m.routeProps.items)
                                    .map(i => <Route {...i} key={i.path}/>)}

                                {[...allModules, ...allCustomerModules, ...allSupplierModules]
                                    .filter(m => isModuleAccessible(m))
                                    .filter(m => m.subModules)
                                    .map(m => m.subModules.map(sub => <Route {...sub.routeProps}
                                                                             key={sub.routeProps.path}/>))}

                                <Route path="*" element={<Navigate to={getDefaultRoute()} replace/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
            <Dispatcher/>
        </Router>
    } else {
        view = <Login/>
    }

    return (
        <AppContext.Provider value={state}>
            <React.StrictMode>
                {view}
            </React.StrictMode>
        </AppContext.Provider>
    );
}
export default App

function Dispatcher() {
    const navigate = useNavigate()

    useMountEffect(() => {
        if (DISPATCH_OBJECT) {
            for (const path of DISPATCH_OBJECT.pathList) {
                console.log("Dispatch to path:", path)
                navigate(path)
            }
        }
    })
}
import {faRotateLeft, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from 'react';
import NetUtils from "../../utils/NetUtils";
import {CloseButton, DownloadButton, MainButton} from "../Buttons/Button";
import {hideLoading, showLoading} from "../Overlay/Loading";
import Spinner from "../Spinner/Spinner";
import './ImageViewer.scss'

const ImageViewer = props => {
    const {
        style,
        onClick
    } = props

    const [url, setUrl] = useState(props.url)
    const [loading, setLoading] = useState(true)

    if (props.url !== url) {
        setUrl(props.url)
        setLoading(true)
    }

    return <div className="ImageViewer" style={style} onClick={onClick}>
        {loading && <Spinner/>}
        <img src={url} alt='Unable to load.'
             style={{opacity: loading ? '0' : '1'}}
             onLoad={() => setLoading(false)}
             onError={() => setLoading(false)}
        />
    </div>
}

export default ImageViewer;


export const ImageViewerModal = props => {
    const {
        url,
        label = '',
        token,
        onReload,
        disabled
    } = props

    const [hash, setHash] = useState('')

    async function onRotate(direction) {
        showLoading()
        await NetUtils.doGet('/api/files/rotate/' + direction + '/' + token)
        setHash('#' + new Date().getTime())
        if (onReload) onReload()
        setTimeout(() => hideLoading(), 400)
    }

    return <div className='ImageViewerModal'>
        <div className='ImageViewerModal-container'>
            <div className='button-bar'>
                <div className='left'>
                    <h3>{label}</h3>
                </div>
                <div className='right'>
                    {
                        !disabled && token &&
                        <>
                            <MainButton faicon={faRotateLeft} onClick={() => onRotate('left')}/>
                            <MainButton faicon={faRotateRight} onClick={() => onRotate('right')}/>
                        </>
                    }

                    <div style={{width: '40px'}}></div>

                    <DownloadButton labelI18n='download' onClick={() => {
                        showLoading()
                        NetUtils.downloadFile(url).then(() => hideLoading())
                    }}/>
                    <CloseButton onClick={props.onHide}/>
                </div>
            </div>
            <div className='content'>
                <ImageViewer url={url + hash}/>
            </div>
        </div>
    </div>
}
import React from 'react';
import './XlnzCard.scss'; // Använd SCSS-fil för styling

const XlnzCard = ({title, subTitle, children, footer, className, style}) => {
    return (
        <div className={`xlnz-card ${className}`} style={style}>
            {
                (title || subTitle) &&
                <div className="xlnz-card-header">
                    {title && <h2 className="xlnz-card-title">{title}</h2>}
                    {subTitle && <h3 className="xlnz-card-subtitle">{subTitle}</h3>}
                </div>
            }
            <div className="xlnz-card-content">
                {children}
            </div>
            {footer && <div className="xlnz-card-footer">{footer}</div>}
        </div>
    );
};

export default XlnzCard;

import NetUtils from "../../utils/NetUtils";


function addFilterBy(list) {
    // console.log("ProjectService-addFilterBy: THIS SHOULD NOT BE USED")
    list.forEach(item => {
        item.filterBy = [item.number, item.name, item.projectLeaderName, item.markup].join(';')
    })
}

const CustomerProjectService = {
    findAll: async params => {
        const result = await NetUtils.doGet('/api/customer/projects', params)
        addFilterBy(result)
        return result
    },
    findById: id => NetUtils.doGet('/api/customer/projects/' + id),
}
export default CustomerProjectService;
import React from 'react';
import XlnzCard from "../../components/XlnzCard/XlnzCard";

const Heap = props => {
    const {
        value
    } = props

    if (!value) return <></>

    return <XlnzCard title='Heap'>
        <div className="Grid">
            <table>
                <tbody>
                <tr>
                    <td>Max Memory:</td>
                    <td>{value.maxMemory} MB</td>
                    <td style={{width: '750px', paddingLeft: '10px', color: '#999'}}>
                        The maximum amount of heap memory that the JVM is allowed to use. It corresponds roughly to the
                        JVM startup parameter -Xmx.
                    </td>
                </tr>
                <tr>
                    <td>Total Memory:</td>
                    <td>{value.totalMemory} MB</td>
                    <td style={{width: '750px', paddingLeft: '10px', color: '#999'}}>
                        The amount of heap memory currently allocated (reserved) by the JVM from the operating system.
                        This value can increase over time as the JVM requests additional memory, up to maxMemory.
                    </td>
                </tr>
                <tr>
                    <td>Free Memory:</td>
                    <td>{value.freeMemory} MB</td>
                    <td style={{width: '750px', paddingLeft: '10px', color: '#999'}}>
                        The amount of currently unused heap memory within the already allocated heap
                        (the unused portion of totalMemory).
                    </td>
                </tr>
                </tbody>
            </table>

            <div style={{display: 'flex', flexDirection: 'row', gap: '50px'}}>
                {
                    value?.gcList.map(gc =>
                        <div key={gc.name}>
                            <h3 style={{marginBottom: '5px'}}>{gc.name}</h3>
                            <table>
                                <tbody>
                                <tr>
                                    <td>Collection Count</td>
                                    <td>{gc.collectionCount}</td>
                                </tr>
                                <tr>
                                    <td>Collection Time</td>
                                    <td>{gc.collectionTime} ms</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </div>
    </XlnzCard>

}
export default Heap;
const Company = {
    XLNZ_TECH: 1,
    VERKTYG1: 42,

    DEMOBOLAGET: 2,
    SCAFFOLDING_PROJECTS: 3,
    VATTERSTADENS: 11,

    ENTER: 19,
    ENTER_UDDEVALLA_BOREALIS: 20,
    ENTER_UDDEVALLA_NOVO: 32,
    ENTER_UDDEVALLA_ST1: 33,

    MOBILBYGG: 28,
    MOBILBYGG_ENTREPRENAD: 43,
    REELS_PROJEKT: 29,

    PBAB: 46
}
export default Company
import NetUtils from "../../utils/NetUtils";

const CustomerQnaService = {
    findAll: params => NetUtils.doGet('/api/customer/qnas', params),
    findById: id => NetUtils.doGet('/api/customer/qnas/' + id),
    findByProject: projectId => NetUtils.doGet('/api/customer/qnas/project/' + projectId),
    update: model => NetUtils.doPost('/api/customer/qnas', model),
    insertMessage: (id, message, files) => NetUtils.doPostWithFiles('/api/customer/qnas/' + id + '/message', {message: message}, files),

    downloadPdf: async id => NetUtils.downloadFile('/api/customer/qnas/' + id + '/pdf'),
}
export default CustomerQnaService;
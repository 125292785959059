import {i18n} from "../../../I18n/I18n";
import {findByField} from "../../../utils/ArrayUtil";

const TO_HANDLE = 'TO_HANDLE';
const ONGOING = 'ONGOING';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';

export default class QnaStatus {
    static get TO_HANDLE() {
        return Object.freeze({
            id: TO_HANDLE,
            label: i18n('to_handle'),
            labelMultiple: i18n('to_handle')
        })
    }

    static get ONGOING() {
        return Object.freeze({
            id: ONGOING,
            label: i18n('ongoing'),
            labelMultiple: i18n('ongoing')
        })
    }

    static get COMPLETED() {
        return Object.freeze({
            id: COMPLETED,
            label: i18n('completed'),
            labelMultiple: i18n('completed_plur')
        })
    }

    static get CANCELLED() {
        return Object.freeze({
            id: CANCELLED,
            label: i18n('cancelled'),
            labelMultiple: i18n('cancelled_plur')
        })
    }

    static values() {
        return [
            this.TO_HANDLE,
            this.ONGOING,
            this.COMPLETED,
            this.CANCELLED,
        ];
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }
}
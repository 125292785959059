import {faBold} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useState} from 'react';
import AppContext from "../../../AppContext";
import Button, {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import ContextMenuItem from "../../../components/ContextMenu/ContextMenuItem";
import CheckboxEditor from "../../../components/Grid/CheckboxEditor";
import {addFreeRows, isFreeRow, numOfFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import onRowChange from "../../../components/Grid/onRowChange";
import TextGridInput from "../../../components/Grid/TextGridInput";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Icon from "../../../enums/Icon";
import useMountEffect from "../../../hooks/useMountEffect";
import {i18n} from "../../../I18n/I18n";
import {deepCopy} from "../../../utils/ObjectUtils";
import TenderService from "../TenderService";

const NUM_OF_FREE_ROWS = 1

const TenderhowtotemplateDialog = props => {
    const context = useContext(AppContext);
    const [list, setList] = useState()

    const getListWithFreeRows = (_list) => addFreeRows(_list, NUM_OF_FREE_ROWS, {
        id: 0,
        company: context.currentCompany.id,
        name: ''
    });

    useMountEffect(async () => {
        showLoading()
        let _list = await TenderService.findAllHowtotemplate()
        _list = getListWithFreeRows(_list)
        setList(_list)
        hideLoading()
    }, [])


    const fireChange = (_list) => {
        _list = getListWithFreeRows(_list)
        setList([..._list])
    }

    const onSave = async () => {
        showLoading()

        const saveList = list.filter(item => item.name.length > 0)
        await TenderService.updateHowtotemplate(saveList)

        const deleteList = list
            .filter(item => item.id > 0)
            .filter(item => item.dirty)
            .filter(item => item.name.trim().length === 0)
        for (const item of deleteList) {
            await TenderService.deleteHowtotemplate(item)
        }

        const selectedList = list
            .slice(0, list.length - numOfFreeRows(list))
            .filter(item => item.selected)
        props.onChange(selectedList);

        hideLoading()
    }

    const columns = [
        {
            field: 'name', header: i18n('description'),
            editor: e => <TextGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            header: i18n('standard_value'),
            field: 'standard',
            body: (rowData, e) =>
                isFreeRow(list, rowData)
                    ? ''
                    : CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange),
            rendererIsEditor: true,
            width: 100
        },
        {
            header: i18n('fetch'),
            field: 'selected',
            body: (rowData, e) =>
                isFreeRow(list, rowData)
                    ? ''
                    : CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange),
            rendererIsEditor: true,
            width: 60
        }
    ]

    const contextMenu = [
        ContextMenuItem({
            i18n: 'new_row', icon: Icon.ADD, command: (selectedRow) => {
                const _selectedRow = selectedRow
                const _list = [...list]
                const index = list.indexOf(_selectedRow)
                _list.splice(index, 0, {
                    id: 0, company: context.currentCompany.id, name: ''
                })
                fireChange(_list)
            }
        }),
        ContextMenuItem({
            i18n: 'copy_row', icon: Icon.COPY, command: (selectedRow) => {
                const _selectedRow = selectedRow
                const _list = [...list]
                const index = list.indexOf(_selectedRow)
                const oldRow = deepCopy(list[index])
                delete oldRow.id
                _list.splice(index + 1, 0, oldRow)
                fireChange(_list)
            }
        }),
        ContextMenuItem({
            i18n: 'bold', icon: faBold, command: (_row) => {
                _row.headline = !_row.headline
                fireChange(list)
            }
        }),
    ].filter(item => !item.hidden);

    const rowClassName = data => {
        return {
            'bold': data.headline,
        }
    }

    if (!list) return <Spinner/>
    return <XlnzDialog headerI18n='templates'
                       onHide={props.onHide}
                       leftButtons={() => [
                           <Button key='select_standard_values' labelI18n='select_standard_values' onClick={() => {
                               const _list = [...list]
                               _list.forEach(item => item.selected = item.standard)
                               setList(_list)
                           }}/>
                       ]}
                       buttons={() => [
                           <SaveButton key='save' onClick={onSave}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div style={{width: '900px'}}>
            <Grid
                columns={columns}
                value={list}
                footerColumnGroup={<div/>}
                onRowReorder={e => fireChange([...e.value])}
                heightOffset={400}
                sortable={false}
                contextMenu={contextMenu}
                rowClassName={rowClassName}
            />
        </div>
    </XlnzDialog>
}

export default TenderhowtotemplateDialog;

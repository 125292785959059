import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import CustomerQnaEditForm from "./edit/CustomerQnaEditForm";
import CustomerQnaGrid from "./CustomerQnaGrid";

const CustomerQnasIndex = {
    routeProps: {
        path: '/customer/qnas',
        exact: true,
        element: <CustomerQnaGrid/>,
    },
    nameKey: 'questions_and_answers',
    icon: Icon.QNA,
    subModules: [{
        routeProps: {
            path: '/customer/qnas/:id',
            exact: true,
            element: <CustomerQnaEditForm/>,
        }
    }],
    roles: [Role.CUSTOMERCONTACT]
};
export default CustomerQnasIndex;
import React from 'react';
import Tooltip from "./components/Overlay/Tooltip";
import Unit from "./enums/Unit";
import {formatCubicMeter, formatSquareMeter, NumberUtils} from "./utils/NumberUtils";
import StringUtils from "./utils/StringUtil";

export class GridTemplates {
    static getValue = (rowData, column) => column.field.split('.').reduce((o, i) => o[i], rowData);
    static getNumber = (rowData, column) => Number(GridTemplates.getValue(rowData, column));


    static integer = (rowData, column) => NumberUtils.format(Number(rowData[column.field]), 0);

    static weight = (rowData, column) => NumberUtils.formatWeight(Number(rowData[column.field]), 0);
    static weightTwoDecimals = (rowData, column) => NumberUtils.formatWeight(GridTemplates.getNumber(rowData, column), 2);

    static currency = (rowData, column) => NumberUtils.formatCurrency(Number(rowData[column.field]), 0);
    static currencyPerHours = (rowData, column) => NumberUtils.formatCurrencyPerHour(Number(rowData[column.field]), 0);
    static currencyPerHoursTwoDecimals = (rowData, column) => NumberUtils.formatCurrencyPerHour(Number(rowData[column.field]), 2);

    static currencyTwoDecimals = (rowData, column) => NumberUtils.formatCurrency(Number(rowData[column.field]), 2)

    static squareMeter = (rowData, column) => formatSquareMeter(Number(rowData[column.field]), 0)
    static cubicMeter = (rowData, column) => formatCubicMeter(Number(rowData[column.field]), 0)

    static numberTwoDecimals = (rowData, column) => {
        return NumberUtils.format(Number(rowData[column.field]), 2);
    }

    static numberTwoDecimalsIfNeeded = (rowData, column) => {
        const value = Number(rowData[column.field]);
        return NumberUtils.format(value, NumberUtils.countDecimals(value));
    }

    static hours(rowData, column) {
        const value = GridTemplates.getValueByString(rowData, column.field)
        return NumberUtils.hours(value)
    }

    static percent(rowData, column) {
        let value = Number(rowData[column.field]);
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            return Math.round(value * 100) + " %";
        }
    }

    static percentTimes100(rowData, column) {
        let value = Number(rowData[column.field]);
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            return value > 1000 ? value : value + " %";
        }
    }

    static time(rowData, column) {
        var timeStr = rowData[column.field];
        if (timeStr) {
            var time = new Date(timeStr);
            return time.toLocaleString();
        } else {
            return '';
        }
    }

    static address(rowData, column) {
        return StringUtils.formatAddress(rowData[column.field])
    }

    static zip(rowData, column) {
        let value = Number(GridTemplates.getValueByString(rowData, column.field))
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            let str = value + '';
            if (str.length === 5) {
                return str.substr(0, 3) + ' ' + str.substr(3, 2);
            } else {
                return value;
            }
        }
    }

    static active(rowData, column) {
        var active = rowData[column.field];
        return active ? 'Aktiv' : 'Inaktiv';
    }

    static boolean(rowData, column) {
        var active = rowData[column.field];
        return active ? 'Ja' : 'Nej';
    }

    static booleanHideFalse(rowData, column) {
        var active = rowData[column.field];
        return active ? 'Ja' : '';
    }

    static unit(rowData, column) {
        const unitStr = GridTemplates.getValueByString(rowData, column.field)
        return unitStr ? Unit.findById(unitStr)?.name : ''
    }

    // static remove(rowData, datatable) {
    //     return <i className="pi pi-times"
    //               style={{cursor: 'pointer'}}
    //               onClick={e2 => {
    //                   datatable.onRowRemove(rowData)
    //               }}/>
    // }

    static formatBytes = (rowData, column) => {
        const value = Number(rowData[column.field])
        if (value === 0) return ''
        return NumberUtils.formatBytes(value, 0);
    }

    static stringLimit100(rowData, column) {
        let str = GridTemplates.getValueByString(rowData, column.field)
        if (str?.length > 100) str = str.substring(0, 100) + '...'
        return str
    }

    static multilineString(rowData, column) {
        const str = GridTemplates.getValueByString(rowData, column.field)
        return <div style={{whiteSpace: 'break-spaces'}}>{str}</div>
    }

    static onelineStringByValue(str, fixedWidth) {
        const style = {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
        if (fixedWidth) style.width = fixedWidth
        return <Tooltip value={str}>
            <div style={style}>{str}</div>
        </Tooltip>
    }

    static onelineString(rowData, column) {
        const str = GridTemplates.getValueByString(rowData, column.field)
        const fixedWidth = column.column?.props?.style?.width
        return GridTemplates.onelineStringByValue(str, fixedWidth)
    }

    static excelBody(row, column) {
        return row[column.field]
    }

    static csvToColumn(rowData, column) {
        const list = GridTemplates.getValueByString(rowData, column.field).split(', ')
        return <div>
            {list.map((item, index) => (
                <div key={index}>
                    {item}
                </div>
            ))}
        </div>
        // .replaceAll(', ', '<br/>')

    }

    static getValueByString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }
}
import React, {useCallback, useEffect, useRef, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import {findByField} from "../../utils/ArrayUtil";
import {isNumeric, round} from "../../utils/NumberUtils";
import {getValueByField} from "../../utils/ObjectUtils";
import ContextMenu from "../ContextMenu/ContextMenu";
import renderOverlay, {removeOverlay} from "../Overlay/renderOverlay";
import useXlnxGridExpandable from "./useXlnxGridExpandable";
import useXlnzGridOutsideClickEvent from "./useXlnzGridOutsideClickEvent";
import './XlnzGrid.scss'
import XlnzGridContent from "./XlnzGridContent";
import XlnzGridCustomFooter from "./XlnzGridCustomFooter";
import XlnzGridFooter from "./XlnzGridFooter";
import XlnzGridHeader from "./XlnzGridHeader";

export default function XlnzGrid(props) {
    const {
        id,
        disabled,
        columns,
        pagination,
        uberColumns = [],
        onRowReorder,
        onRowSelect,
        width,
        scrollHeight,
        sortable = true,
        onSortFieldChange,
        onSortOrderChange,
        contextMenuOptions,
        hideHeader = false,
        hideFooter = false,
        customFooter,
        selection,
        onSelectionChange,

        rowClassName,
        cellClassName,

        expandableField
    } = props

    const [WINDOW_WIDTH] = useWindowSize()
    const [gridWidth, setGridWidth] = useState(WINDOW_WIDTH)

    const getSessionState = useCallback(field => {
        if (id) return sessionStorage.getItem(id + '-' + field)
    }, [id])

    const setSessionState = useCallback((field, value) => {
        if (id) {
            if (value) sessionStorage.setItem(id + '-' + field, value)
            else sessionStorage.removeItem(id + '-' + field)
        }
    }, [id])

    const [sortField, setSortField] = useState(getSessionState('sortField'))
    const [sortOrder, setSortOrder] = useState(getSessionState('sortOrder'))

    const [contextMenuId, setContextMenuId] = useState()
    const [contextMenuProps, setContextMenuProps] = useState()

    const xlnxGridExpandable = useXlnxGridExpandable({...props, getSessionState, setSessionState})

    const ref = useRef()

    const value = getSortedValue(props.value)

    useEffect(() => {
        // if (Env.isDev() && !id) console.log("Grid: No ID present")
        if (id && value?.length > 0) {
            const gridElement = document.querySelector('.XlnzReactGrid');
            if (gridElement) {
                const scrollPosition = getSessionState('scrollPosition');
                if (scrollPosition !== null) gridElement.scrollTop = scrollPosition


                gridElement.addEventListener('scroll', () => setSessionState('scrollPosition', gridElement.scrollTop))
            }
        }
    }, [id, value, getSessionState, setSessionState]);

    function getSortedValue(value) {
        if (!sortField) return value;
        const _value = [...value];

        // Hitta sorteringsfunktionen eller använd standardjämförelse
        const sortFunction = findByField(columns, 'field', sortField)?.sortFunction;
        const comparator = sortFunction
            ? sortFunction
            : (a, b) => {
                const aVal = getValueByField(a, sortField, '') || ''
                const bVal = getValueByField(b, sortField, '') || ''

                // console.log("aVal", aVal, "bVal", bVal)

                if (isNumeric(aVal) && isNumeric(bVal)) {
                    return Number(aVal) - Number(bVal);
                } else {
                    // return aVal.localeCompare(bVal);
                    return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
                }
            };

        // Gruppera objekten baserat på `topParent`
        const groupedItems = _value.reduce((groups, item) => {
            const topParentId = item.topParent ?? item.id;
            if (!groups[topParentId]) {
                groups[topParentId] = [];
            }
            groups[topParentId].push(item);
            return groups;
        }, {});

        // Sortera varje grupp och inkludera huvudföräldern i sorteringen
        const sortedItems = Object.values(groupedItems)
            .map(group => {
                group.sort(comparator); // Sortera hela gruppen istället för att separera huvudföräldern
                return group;
            })
            .sort((a, b) => comparator(a[0], b[0])) // Sortera grupperna baserat på första objektet (huvudföräldern)
            .flat();

        // Omvänd sortering om `sortOrder` är negativ
        return sortOrder < 0 ? sortedItems.reverse() : sortedItems;
    }

    // function getSortedValue(value) {
    //     if (!sortField) return value;
    //     const _value = [...value];
    //     // console.log("_value", _value)
    //
    //     // Hitta sorteringsfunktionen eller använd standardjämförelse
    //     const sortFunction = findByField(columns, 'field', sortField)?.sortFunction;
    //     const comparator = sortFunction
    //         ? sortFunction
    //         : (a, b) => {
    //             const aVal = getValueByField(a, sortField, '');
    //             const bVal = getValueByField(b, sortField, '');
    //
    //             if (isNumeric(aVal) && isNumeric(bVal)) {
    //                 const aValNum = Number(aVal);
    //                 const bValNum = Number(bVal);
    //                 return aValNum > bValNum ? 1 : bValNum > aValNum ? -1 : 0;
    //             } else {
    //                 return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    //             }
    //         };
    //
    //     // Steg 1: Gruppera objekten baserat på `topParent`
    //     const groupedItems = _value.reduce((groups, item) => {
    //         // Använd `item.id` som nyckel om det är en huvudförälder (topParent är `null` eller `undefined`), annars `item.topParent`
    //         const topParentId = item.topParent ?? item.id;
    //         if (!groups[topParentId]) {
    //             groups[topParentId] = [];
    //         }
    //         groups[topParentId].push(item);
    //         return groups;
    //     }, {});
    //
    //     // Steg 2: Sortera varje grupp och placera huvudföräldern först, följt av barnen
    //     const sortedItems = Object.values(groupedItems)
    //         .map(group => {
    //             // Hitta huvudföräldern i gruppen (de utan en `topParent` eller där `topParent` är deras eget `id`)
    //             const topParent = group.find(item => item.topParent === undefined || item.topParent === item.id);
    //             const children = group.filter(item => item !== topParent);
    //             console.log("topParent", topParent)
    //             // Sortera barnen baserat på sorteringsfältet
    //             children.sort(comparator);
    //
    //             // Kombinera huvudföräldern med de sorterade barnen
    //             return [topParent, ...children];
    //         })
    //         .sort((a, b) => comparator(a[0], b[0])) // Sortera grupperna baserat på huvudföräldern
    //         .flat();
    //
    //     // Omvänd sortering om `sortOrder` är negativ
    //     return sortOrder < 0 ? sortedItems.reverse() : sortedItems;
    // }

    function getColumnWidth(col) {
        // if(!col) return 0
        if (!col) console.log("col", col, columns)

        if (col.width) return col.width + 'px'

        const fixedWidths = columns
            .filter(col => col.width > 0)
            .reduce((sum, col) => sum + col.width, 0)

        const numOfUnwidthed = columns.filter(col => !col.width).length
        const scrollWidth = scrollHeight ? 20 : 2 //Donno why 2?? Maybe because of the red test border.

        let widthToUse = width ? width : gridWidth

        if (expandableField) widthToUse -= 25

        return round((widthToUse - fixedWidths - scrollWidth) / numOfUnwidthed, 2) + 'px'
    }


    useEffect(() => {
        setGridWidth(ref.current.offsetWidth)
    }, [WINDOW_WIDTH]);

    useXlnzGridOutsideClickEvent(() => {
        // This is a hack. We have no width of thew component until it is rendered. Check this with the new tab navigator. //JW 2023-09-26
        setGridWidth(ref.current.offsetWidth)
        setContextMenuProps(undefined)
    })

    function hideContextMenu() {
        if (contextMenuId) {
            removeOverlay(contextMenuId)
            setContextMenuId(undefined)
        }
    }

    function _onRowSelect(e) {
        hideContextMenu()
        if (onRowSelect) onRowSelect(e)
    }

    useEffect(() => {
        hideContextMenu()
        if (contextMenuProps) {
            const id = renderOverlay(<ContextMenu options={contextMenuOptions} {...contextMenuProps} />)
            setContextMenuId(id)
        }
        // eslint-disable-next-line
    }, [contextMenuProps]);

    return <div ref={ref} className='XlnzReactGrid' style={{
        maxHeight: scrollHeight,
        // borderTop: Env.isDev() ? '1px solid green' : undefined
    }}>
        {!width && !gridWidth && <div>No gridWidth</div>}

        {
            (width || gridWidth) > 0 &&
            <>
                {/*{contextMenuProps && <ContextMenu options={contextMenuOptions} {...contextMenuProps} />}*/}

                {
                    !hideHeader &&
                    <XlnzGridHeader
                        disabled={disabled}
                        uberColumns={uberColumns}
                        columns={columns}
                        data={value}
                        getColumnWidth={getColumnWidth}

                        selection={selection}
                        onSelectionChange={onSelectionChange}

                        sortable={sortable}
                        sortField={sortField}
                        onSortFieldChange={_sortfield => {
                            setSortField(_sortfield)
                            setSessionState('sortField', _sortfield)
                            if (onSortFieldChange) onSortFieldChange(_sortfield)
                        }}
                        sortOrder={sortOrder}
                        onSortOrderChange={_sortOrder => {
                            setSortOrder(_sortOrder)
                            setSessionState('sortOrder', _sortOrder)
                            if (onSortOrderChange) onSortOrderChange(_sortOrder)
                        }}
                        xlnxGridExpandable={xlnxGridExpandable}
                    />
                }


                <XlnzGridContent
                    disabled={disabled}
                    columns={columns}
                    data={value}
                    pagination={pagination}
                    getColumnWidth={getColumnWidth}

                    selection={selection}
                    onSelectionChange={onSelectionChange}
                    onRowSelect={_onRowSelect}
                    rowClickable={!!onRowSelect}

                    onRowReorder={onRowReorder}

                    contextMenuOptions={contextMenuOptions}
                    setContextMenuProps={setContextMenuProps}

                    rowClassName={rowClassName}
                    cellClassName={cellClassName}

                    xlnxGridExpandable={xlnxGridExpandable}
                />

                {
                    !hideFooter &&
                    <XlnzGridFooter
                        columns={columns}
                        data={value}
                        getColumnWidth={getColumnWidth}
                        xlnxGridExpandable={xlnxGridExpandable}
                    />
                }
                {
                    customFooter &&
                    <XlnzGridCustomFooter
                        columns={columns}
                        data={value}
                        getColumnWidth={getColumnWidth}
                        customFooter={customFooter}
                        xlnxGridExpandable={xlnxGridExpandable}
                    />
                }
            </>
        }
    </div>
}

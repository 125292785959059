import {faBold, faCalculator, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Icon from "../../enums/Icon";
import {i18n} from "../../I18n/I18n";
import {findByObject} from "../../utils/ArrayUtil";
import NetUtils from "../../utils/NetUtils";
import {deepCopy} from "../../utils/ObjectUtils";
import ContextMenuItem from "../ContextMenu/ContextMenuItem";
import renderOverlay from "../Overlay/renderOverlay";
import Calculator from "./Calculator";

export default function getFormItemRowGridContextMenu(props) {
    const {
        disabled,
        rowList,
        selectedRow: selectedRowDepricated,
        getNewRow,
        fireChange,
        columns,
        isCellEditable,
        visibleItems
    } = props

    function hideEditableContextMenuItem(selectedRow) {
        const _selectedRow = selectedRow ? selectedRow : selectedRowDepricated
        if (disabled) return true
        const _row = findByObject(rowList, _selectedRow)
        return disabled || _row === undefined || _row.readonly
    }

    function isHidden(name) {
        if (!visibleItems) return false;
        return !findByObject(visibleItems, name)
    }

    return [
        ContextMenuItem({
            i18n: 'new_row', icon: Icon.ADD, command: (selectedRow) => {
                const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                const _rowList = [...rowList]
                const index = rowList.indexOf(_selectedRow)
                _rowList.splice(index, 0, getNewRow())
                fireChange(_rowList)
            },
            hidden: selectedRow => hideEditableContextMenuItem(selectedRow) || isHidden('new_row')
        }),
        ContextMenuItem({
            i18n: 'copy_row', icon: Icon.COPY, command: (selectedRow) => {
                const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                const _rowList = [...rowList]
                const index = rowList.indexOf(_selectedRow)
                const oldRow = deepCopy(rowList[index])
                delete oldRow.id
                _rowList.splice(index + 1, 0, oldRow)
                fireChange(_rowList)
            },
            hidden: selectedRow => hideEditableContextMenuItem(selectedRow) || isHidden('copy_row')
        }),
        ContextMenuItem({
            i18n: 'calculator', icon: faCalculator, command: (selectedRow) => {
                const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                const _row = findByObject(rowList, _selectedRow)
                renderOverlay(<Calculator onSave={val => {
                    _row.unit = val.outUnit
                    _row.quantity = val.outQuantity
                    _row.aprice = val.outAprice
                    _row.price = val.outPrice
                    fireChange(rowList)
                }}/>)
            },
            hidden: selectedRow => hideEditableContextMenuItem(selectedRow) || isHidden('calculator')
        }),
        ContextMenuItem({
            i18n: 'bold', icon: faBold, command: (selectedRow) => {
                const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                const _row = findByObject(rowList, _selectedRow)
                _row.headline = !_row.headline
                fireChange(rowList)
            },
            hidden: selectedRow => hideEditableContextMenuItem(selectedRow) || isHidden('bold')
        }),
        ContextMenuItem({
            i18n: 'show_only_total', icon: faEyeSlash, command: (selectedRow) => {
                const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                const _row = findByObject(rowList, _selectedRow)
                _row.onlyTotal = !_row.onlyTotal
                fireChange(rowList)
            },
            hidden: selectedRow => hideEditableContextMenuItem(selectedRow) || isHidden('show_only_total')
        }),
        ContextMenuItem({
            i18n: 'clear_the_list', icon: Icon.REMOVE, command: (selectedRow) => {
                const _rowList = rowList.filter(rowData => isCellEditable && !isCellEditable('remove', rowData))
                fireChange(_rowList)
            },
            hidden: selectedRow => hideEditableContextMenuItem(selectedRow) || isHidden('clear_the_list')
        }),
        ContextMenuItem({
            i18n: 'export_to_excel', icon: Icon.EXCEL, command: selectedRow => {
                // const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                // const _row = findByObject(rowList, _selectedRow)
                // _row.onlyTotal = !_row.onlyTotal
                // fireChange(rowList)
                NetUtils.exportToExcel(i18n('rows'), undefined, columns, rowList)
            },
            hidden: (selectedRow) => {
                const _selectedRow = selectedRowDepricated ? selectedRowDepricated : selectedRow
                const _row = findByObject(rowList, _selectedRow)
                return _row === undefined || isHidden('export_to_excel')
            }
        }),
    ]
}
import React, {useCallback, useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import Button, {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemRowGrid from "../../../components/EditForm/FormItemRowGrid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Icon from "../../../enums/Icon";
import RentType from "../../../enums/RentType";
import useMountEffect from "../../../hooks/useMountEffect";
import {i18n} from "../../../I18n/I18n";
import {findRemovedItems} from "../../../utils/ArrayUtil";
import ProjectrentService from "../ProjectrentService";
import ProjectService from "../ProjectService";

export const ProjectrentButton = props => {
    const {
        handleSubmit,
        saveProject,
        dialog,
        getValues,
        setValue,
        control
    } = props

    const [count, setCount] = useState(0);

    const loadCount = useCallback(async () => {
        const _projectId = getValues('id')
        if (_projectId > 0) {
            const res = await ProjectrentService.count(_projectId)
            setCount(res.value)
        }
    }, [getValues]);

    const watchLoadProjectrentCountEvent = !!useWatch({control, name: 'loadProjectrentCountEvent'})

    useEffect(() => {
            if (watchLoadProjectrentCountEvent) {
                setValue('loadProjectrentCountEvent', false)
                loadCount().then(r => {
                })
            }
        },
        [watchLoadProjectrentCountEvent, loadCount, setValue]
    )

    useMountEffect(async () => {
        await loadCount()
    })

    const showDialog = data => {
        showLoading();
        saveProject(data).then(async _project => {
            if (_project) {
                const _projectrentList = await ProjectService.findRentsByProject(_project.id)

                dialog.show(<ProjectrentDialog
                    onAfterSave={async () => {
                        await loadCount()
                    }}
                    project={_project}
                    projectrentList={_projectrentList}
                    // onHide={() => setShowGenerateRentDialog(false)}
                    getValues={getValues}
                    saveProject={saveProject}
                />)

                hideLoading();
            } else {
                hideLoading();
            }
        })
    }

    if (count === 0) return undefined
    return <div>
        <Button
            label={i18n('rent_rows') + ' (' + count + ')'}
            faicon={Icon.LOG}
            onClick={handleSubmit(showDialog)}
        />
    </div>
}

const ProjectrentDialog = props => {
    const {
        disabled,
        project,
        projectrentList,
        onAfterSave,
        onHide
    } = props

    const defaultValues = {
        projectrentList: projectrentList
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, formState: {isSubmitting}} = form

    const onSave = async (data) => {
        showLoading();

        const updatedRows = data.projectrentList.filter(item => item.dirty)
        for (const updatedRow of updatedRows) {
            await ProjectrentService.update(updatedRow)
        }
        // console.log("updatedRows", updatedRows)

        const removedRows = findRemovedItems(projectrentList, data.projectrentList)
        const removeIdList = removedRows.map(item => item.id)
        if (removeIdList.length > 0) {
            await ProjectrentService.deleteBatch(removeIdList)
        }
        // for (const removedRow of removedRows) {
        //     await ProjectrentService.delete(removedRow)
        // }
        // console.log("deletedRows", removedRows)
        await onAfterSave()
        onHide()
        hideLoading();

    }

    const onSubmit = (data) => {
        onSave(data)
    }

    function isRentInvoiced(pr) {
        const _tr = projectrentList?.find(item => item.id === pr.id)
        return _tr?.invoicerowprojectrentList.length > 0
    }

    return <XlnzDialog header={i18n('generate_rent') + ': ' + project.name}
                       onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className='EditForm' style={{width: '900px', margin: 0}}>

            {/*<TabPanel key='rent_rows' header={i18n('rent_rows') + ' (' + (watchRentList?.length) + ')'}>*/}
            <FormItemRowGrid name='projectrentList' numOfFreeRows={0} {...form}
                             isCellEditable={(field, rowData) => !isRentInvoiced(rowData)}
                // articleList={common.articleList}
                // pricelist={pricelist}
                             getCustomColumns={(rowList, fireChange) => {
                                 return [{
                                     field: 'type', header: i18n('type'),
                                     body: row => RentType.getName(row.type),
                                     afterField: 'name',
                                     width: 100,
                                 }]
                             }}
                             scrollable={true}
                             heightOffset={400}

                             pagination={true}

                             contextMenuProps={{
                                 visibleItems: [
                                     'show_only_total',
                                     'clear_the_list',
                                     'export_to_excel'
                                 ]
                             }}
            />
            {/*</TabPanel>*/}
        </div>
    </XlnzDialog>
}
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ContextMenuItem from "../../../../components/ContextMenu/ContextMenuItem";
import {useFilter} from "../../../../components/Grid/filter";
import Grid from "../../../../components/Grid/Grid";
import TextAreaGridInput from "../../../../components/Grid/TextAreaGridInput";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Tooltip from "../../../../components/Overlay/Tooltip";
import Companyprop from "../../../../enums/Companyprop";
import Icon from "../../../../enums/Icon";
import Role from "../../../../enums/Role";
import {GridTemplates} from "../../../../GridTemplates";
import useCompanyProp from "../../../../hooks/useCompanyProp";
import useDialog from "../../../../hooks/useDialog";
import usePreload from "../../../../hooks/usePreload";
import useRoles from "../../../../hooks/useRoles";
import useWindowSize from "../../../../hooks/useWindowSize";
import {i18n} from "../../../../I18n/I18n";
import {GenerateRentDialog} from "../../../Projects/edit/GenerateRentButton";
import ProjectService from "../../../Projects/ProjectService";
import ProjectSummaryDialog from "../../../Projects/ProjectSummary/ProjectSummaryDialog";
import TenderService from "../../../Tenders/TenderService";
import DayplanningService from "../DayplanningService";
import DayplanningCopyDialog from "./DayplanningCopyDialog";
import EmployeeInfoGridTemplete from "./EmployeeInfoGridTemplete";
import RentInfoGridTemplete from "./RentInfoGridTemplete";

const DayplanningGrid = props => {
    const roles = useRoles()
    const preload = usePreload()
    const [width] = useWindowSize()
    const dialog = useDialog()

    const employeeList = preload.getEmployeeList()
    const timeregsalarytypeList = preload.getTimeregsalarytypeList()

    const {
        dayplanningList,
        // dayplanningListChangeHandler,
        leftFilters,
        rightFilters,
        onReload,
        heightOffset = 370
    } = props

    const filter = useFilter('DayplanningGrid', [])

    const COMPANY_TYPE_SCAFFOLDER = useCompanyProp(Companyprop.COMPANY_TYPE_SCAFFOLDER, false)

    const [projectAndtender, setProjectAndtender] = useState()

    const navigate = useNavigate()

    // const leftFilters = [
    //     roles.hasRole(Role.TIMEREG_READ) ? <div>
    //         <label className="label">&nbsp;</label>
    //         <AddButton params={'/' + date}/>
    //     </div> : undefined,
    // ]

    // const fireChange = (list) => {
    //     dayplanningListChangeHandler(list)
    // }

    const onCellEditComplete = async e => {
        if (e.newValue !== e.oldValue) {
            showLoading()
            const dayplanning = await DayplanningService.findById(e.rowData.id)
            dayplanning[e.field] = e.newValue
            await DayplanningService.update(dayplanning)
            hideLoading()
        }
    }

    const multilineRenderer = (rowData, column) => {
        const str = GridTemplates.getValueByString(rowData, column.field)
        return <div className='p-editable-column'
                    style={{
                        height: '100%',
                        whiteSpace: 'break-spaces',
                        borderLeft: '1px solid #eee',
                        padding: '0 10px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
        >{str}</div>
    }
    // const multilineEditor = e => {
    //     return <InputTextarea
    //         value={e.rowData[e.field]}
    //         maxLength={500}
    //         onChange={e2 => onRowChange(e, e2.target.value, fireChange)}
    //         style={{height: '100%', width: '100%'}}
    //     />
    // }

    const columns = [
        {
            field: 'projectName', headerI18n: 'project',
            body: item => <div>
                <Tooltip value={i18n('reference_no') + ': ' + item.projectMarkup}>
                    <div style={{fontWeight: 'bold'}}>{item.projectName}</div>
                    <div>{item.customerName}</div>
                </Tooltip>
            </div>,
            width: 250,
            mobile: true
        },
        {
            field: 'employeeName', headerI18n: 'employee',
            body: dayplanning => <EmployeeInfoGridTemplete
                dayplanning={dayplanning}
                employeeList={employeeList}
                timeregsalarytypeList={timeregsalarytypeList}
            />,
            mobile: true
        },
        {field: 'billingTypeName', headerI18n: 'billing_type', width: 100},
        {
            field: 'rentInfoGridTemplete', headerI18n: 'rent',
            body: dayplanning => <RentInfoGridTemplete dayplanning={dayplanning}/>,
            mobile: true,
            align: 'center',
            width: 40
        },
        {field: 'invoiceNote', headerI18n: 'invoicing', width: 80},
        {field: 'car', headerI18n: 'car', width: 60},
        {
            field: 'todo',
            header: <div style={{paddingLeft: '10px'}}>{i18n('todo')}</div>,
            body: multilineRenderer,
            editor: e => <TextAreaGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: onCellEditComplete,
            width: width * 0.15,
        },
        {
            field: 'workDone',
            header: <div style={{paddingLeft: '10px'}}>{i18n('work_done')}</div>,
            body: multilineRenderer,
            editor: e => <TextAreaGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: onCellEditComplete,
            width: width * 0.15
        },
        {
            field: 'note',
            header: <div style={{paddingLeft: '10px'}}>{i18n('note')}</div>,
            body: multilineRenderer,
            editor: e => <TextAreaGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: onCellEditComplete,
            width: width * 0.15
        },
    ];


    const contextMenu = [
        ContextMenuItem({
            i18n: 'project_summary', icon: Icon.LOG,
            command: item => dialog.show(<ProjectSummaryDialog projectId={item.project} onClose={onReload}/>)
        }),
        ContextMenuItem({
            i18n: 'edit_tender', icon: Icon.TENDER,
            command: item => navigate('/tenders/' + item.tender),
            hidden: item => !item.tender || !roles.hasRole(Role.TENDER_READ),
        }),
        ContextMenuItem({
            i18n: 'edit_project', icon: Icon.PROJECT,
            command: item => navigate('/projects/' + item.project),
            hidden: item => !roles.hasRole(Role.PROJECT_READ),
        }),
        ContextMenuItem({
            i18n: 'generate_rent', icon: Icon.GENERATE,
            command: async item => {
                const project = await ProjectService.findById(item.project)
                const tender = project.tender > 0 && roles.hasRole(Role.TENDER_READ)
                    ? await TenderService.findById(project.tender) : undefined
                setProjectAndtender({
                    project: project,
                    tender: tender
                })
            },
            hidden: item => !item || !roles.hasRole(Role.PROJECT_READ) || !COMPANY_TYPE_SCAFFOLDER,
        }),
        ContextMenuItem({
            i18n: 'do_invoice', icon: Icon.INVOICE,
            command: item => navigate('/invoices/0?project=' + item.project),
            hidden: item => !roles.hasRole(Role.INVOICE_WRITE)
        }),
        ContextMenuItem({
            i18n: 'create_workorder', icon: Icon.PDF,
            command: item => DayplanningService.downloadWorkorderPdf(item.id),
        }),
        ContextMenuItem({
            i18n: 'do_copy', icon: Icon.COPY,
            // command: item => renderOverlay(<DayplanningCopyDialog dayplanning={item} visible={true}/>),
            command: item => dialog.show(<DayplanningCopyDialog dayplanning={item}/>),
        }),
    ]

    return <div>
        <Grid
            leftFilters={leftFilters}
            rightFilters={rightFilters}
            hideRightFilters={true}
            columns={columns}
            value={dayplanningList}
            onRowSelect={e => {
                window.location.href = '/#/timereg/dayplanning/' + e.data.id + '/' + e.data.date
            }}
            contextMenu={contextMenu}
            heightOffset={heightOffset}
            filter={filter}
        />
        {
            projectAndtender &&
            <GenerateRentDialog {...projectAndtender}
                                onAfterGenerate={() => setProjectAndtender(undefined)}
                                onHide={() => setProjectAndtender(undefined)}/>
        }
        {dialog.render()}
    </div>
}

export default DayplanningGrid;

import Company from "../../enums/Company";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import ProjectEditForm from "./edit/ProjectEditForm";
import ProjectGrid from "./ProjectGrid";
import ProjectInvoiceOverviewGrid from "./ProjectInvoiceOverviewGrid";
import ProjectResultOverviewGrid from "./ProjectResultOverviewGrid";
import QnaEditForm from "./Qnas/edit/QnaEditForm";
import QnaGrid from "./Qnas/QnaGrid";

const InvoicesIndex = {
    routeProps: {
        path: '/projects',
        exact: true,
        element: <ProjectGrid/>,
        onRowSelectPath: '/projects',
        items: [
            {
                nameKey: 'all_projects',
                path: '/projects/list',
                exact: true,
                element: <ProjectGrid/>,
                onRowSelectPath: '/projects'
            },
            {
                nameKey: 'questions_and_answers',
                path: '/projects/qnas',
                exact: true,
                element: <QnaGrid/>,
                isAccessable: context => {
                    // console.log("context.currentCompany.id", context.currentCompany.id)
                    //useBetaQna
                    return context.user.dev
                        || context.currentCompany.id === Company.DEMOBOLAGET
                        || context.currentCompany.id === Company.MOBILBYGG_ENTREPRENAD
                        || context.currentCompany.id === Company.REELS_PROJEKT
                }
            },
            {
                nameKey: 'invoice_overview',
                path: '/projects/invoice-overview',
                exact: true,
                element: <ProjectInvoiceOverviewGrid/>,
                onRowSelectPath: '/projects'
            },
            {
                nameKey: 'result_overview',
                path: '/projects/result-overview',
                exact: true,
                element: <ProjectResultOverviewGrid/>,
                onRowSelectPath: '/projects'
            }
        ]
    },
    nameKey: 'projects',
    icon: Icon.PROJECT,
    subModules: [
        {
            routeProps: {
                path: '/projects/:id',
                exact: true,
                element: <ProjectEditForm/>,
            }
        },
        {
            routeProps: {
                path: '/projects/qnas/:id',
                exact: true,
                element: <QnaEditForm/>,
            }
        },
    ],
    roles: [Role.PROJECT_READ]
};
export default InvoicesIndex;
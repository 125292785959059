import React, {useContext} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import AccessType from "../../enums/AccessType";
import useApiVersion from "../../hooks/useApiVersion";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import {i18n} from "../../I18n/I18n";
import {getModule, modules, registerModules, settingModules} from "../../modules/modules";
import {modulesCustomer} from "../../modulesCustomer/modules";
import {modulesSupplier} from "../../modulesSupplier/modules";
import {sortByField} from "../../utils/ArrayUtil";
import RouterUtils from "../../utils/RouterUtils";
import {MainButton} from "../Buttons/Button";
import DesktopHeader from "./DesktopHeader";

import './Header.scss';

const Header = props => {
    const context = useContext(AppContext)
    const location = useLocation()
    const navigate = useNavigate()
    const userPersmissions = useUserPersmissions()
    const apiVersion = useApiVersion()

    const isModuleAccessible = m => {
        return userPersmissions.hasRoles(m.roles) &&
            userPersmissions.hasRestrictPath(m.routeProps.path)
    }

    const isSubModuleAccessible = m => {
        return userPersmissions.hasRestrictPath(m.path)
    }

    const getCurrentItem = () => {
        let m = getModule(location.pathname)
        return m ? {
            label: m.name ? m.name : i18n(m.nameKey),
            icon: m.icon,
            command: () => navigate(m.routeProps.path)
        } : undefined;
    }


    const getModuleItems = () => {
        let _modules

        switch (context.currentCompany.accessType) {
            default:
            case AccessType.USER.id: {
                _modules = modules
                break;
                // return modules
                //     .filter(m => isModuleAccessible(m))
                //     .map(m => {
                //             function getItems(m) {
                //                 const result = m.routeProps.items?.length > 0 && m.routeProps.items
                //                     .filter(item => isSubModuleAccessible(item))
                //                     .filter(item => !item.isAccessable || item.isAccessable(context))
                //                     .map(i => ({
                //                         label: i.name ? i.name : i18n(i.nameKey),
                //                         icon: i.icon,
                //                         command: () => navigate(i.path)
                //                     }))
                //                 return m.sortByLabel ? sortByField(result, 'label') : result
                //             }
                //
                //             const result = {
                //                 label: m.name ? m.name : i18n(m.nameKey),
                //                 icon: m.icon,
                //                 items: getItems(m)
                //             }
                //
                //             if (!m.routeProps.items) {
                //                 result.command = () => navigate(m.routeProps.path)
                //             }
                //
                //             return result
                //         }
                //     );
            }
            case AccessType.CUSTOMERCONTACT.id: {
                _modules = modulesCustomer
                break;
            }
            case AccessType.SUPPLIERCONTACT.id: {
                _modules = modulesSupplier
                break;
                // return modulesSupplier
                //     .filter(m => isModuleAccessible(m))
                //     .map(m => {
                //             function getItems(m) {
                //                 const result = m.routeProps.items?.length > 0 && m.routeProps.items
                //                     .filter(item => isSubModuleAccessible(item))
                //                     .filter(item => !item.isAccessable || item.isAccessable(context))
                //                     .map(i => ({
                //                         label: i.name ? i.name : i18n(i.nameKey),
                //                         icon: i.icon,
                //                         command: () => {
                //                             navigate(i.path);
                //                         }
                //                     }))
                //                 return m.sortByLabel ? sortByField(result, 'label') : result
                //             }
                //
                //             const result = {
                //                 label: m.name ? m.name : i18n(m.nameKey),
                //                 icon: m.icon,
                //                 items: getItems(m)
                //             }
                //
                //             if (!m.routeProps.items) {
                //                 result.command = () => {
                //                     navigate(m.routeProps.path);
                //                 }
                //             }
                //
                //             return result
                //         }
                //     );
            }
        }

        return _modules
            .filter(m => isModuleAccessible(m))
            .map(m => {
                    function getItems(m) {
                        const result = m.routeProps.items?.length > 0 && m.routeProps.items
                            .filter(item => isSubModuleAccessible(item))
                            .filter(item => !item.isAccessable || item.isAccessable(context))
                            .map(i => ({
                                label: i.name ? i.name : i18n(i.nameKey),
                                icon: i.icon,
                                command: () => navigate(i.path)
                            }))
                        return m.sortByLabel ? sortByField(result, 'label') : result
                    }

                    const result = {
                        label: m.name ? m.name : i18n(m.nameKey),
                        icon: m.icon,
                        items: getItems(m)
                    }

                    if (!m.routeProps.items) {
                        result.command = () => navigate(m.routeProps.path)
                    }

                    return result
                }
            );
    };


    const getRegisterModuleItems = () => sortByField(registerModules.filter(m => isModuleAccessible(m))
        .map(m => ({
            label: m.name ? m.name : i18n(m.nameKey),
            // icon: m.icon,
            command: () => navigate(m.routeProps.path)
        })), 'label')

    const getSettingModuleItems = () => sortByField(settingModules.filter(m => isModuleAccessible(m))
        .map(m => ({
            label: m.name ? m.name : i18n(m.nameKey),
            // icon: m.icon,
            command: () => navigate(m.routeProps.path)
        })), 'label')


    return (
        <div className="Header">

            {
                apiVersion.isNewVersionAvailable() &&
                process.env.NODE_ENV === "production" &&
                <div className='new-version'>
                    <div>{i18n('new_version_info')}</div>
                    <MainButton onClick={() => RouterUtils.reload()} labelI18n='update'/>
                </div>
            }

            <DesktopHeader
                currentItem={getCurrentItem()}
                moduleItems={getModuleItems()}
                registerModuleItems={getRegisterModuleItems()}
                settingModuleItems={getSettingModuleItems()}
            />
        </div>
    )
}

export default Header


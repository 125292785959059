import React from 'react';
import {addFreeRows, numOfFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../components/Grid/SelectEditor";
import TextGridInput from "../../../components/Grid/TextGridInput";
import {i18n} from "../../../I18n/I18n";
import Formrowtype from "./Formrowtype";

const NUM_OF_FREE_ROWS = 1

const FormrowGrid = props => {

    const rowList = addFreeRows(props.value, NUM_OF_FREE_ROWS, {
        id: 0,
        type: Formrowtype.TEXT.id,
        name: '',
        value: '',
        defaultValue: '',
        key: '',
        example: '',
        condition: '',
        printCondition: '',
        style: ''
    })

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = (list) => {
        props.onChange(getSlicedList(list));
    }

    const columns = [
        {
            field: 'type',
            header: i18n('type'),
            body: row => <div className='nowrap'
                              style={{width: '250px'}}>{row.type ? Formrowtype.findById(row.type)?.name : ''}</div>,
            editor: e => <SelectEditor event={e} options={Formrowtype.values()}
                                       optionValue='id' optionLabel='name'
                                       onChange={e2 => e.editorCallback(e2?.value, true)}
            />,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
        },
        {
            field: 'name', headerI18n: 'name',
            body: rowData => <span
                className={
                    rowData.type === Formrowtype.HEADER1.id ||
                    rowData.type === Formrowtype.HEADER2.id
                        ? 'bold' : ''}>
                {rowData.name}
            </span>,
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            field: 'value', headerI18n: 'value',
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            // width: 250,
        },
        {
            field: 'defaultValue', headerI18n: 'standard_value',
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 90,
        },
        {
            field: 'example', header: 'Example',
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
        },
        {
            field: 'condition', header: 'Condition',
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 150,
        },
        {
            field: 'printCondition', header: 'Print Condition',
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 150,
        },
        {
            field: 'key', header: 'Key',
            editor: e => <TextGridInput editorEvent={e} maxLength={100}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 150,
        },
        {
            field: 'style', header: 'Style',
            editor: e => <TextGridInput editorEvent={e} maxLength={100}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 150,
        },
        {
            field: 'blockNo', header: 'Block',
            editor: e => <NumberGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 40,
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))]


    return <div className='FormrowGrid'>
        <Grid columns={columns}
              value={rowList}
              footerColumnGroup={<div/>}
              onRowReorder={e => fireChange([...e.value])}
              sortable={false}
              scrollable={false}
        />
    </div>
}
export default FormrowGrid;
import {
    faAdd,
    faArrowsUpDownLeftRight,
    faBell,
    faBook,
    faCalendarDays,
    faCircleExclamation,
    faCity,
    faClock,
    faComments,
    faCompassDrafting,
    faCopy,
    faDownload,
    faEnvelope,
    faEye,
    faFile,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFolderOpen,
    faHandshake,
    faInfoCircle,
    faLink,
    faLock,
    faMagicWandSparkles,
    faPersonCircleCheck,
    faPlugCircleExclamation,
    faPuzzlePiece,
    faQuestion,
    faSackDollar,
    faSackXmark,
    faTasks,
    faTrash,
    faTruck,
    faUnlock,
    faUserClock,
    faUsersLine
} from "@fortawesome/free-solid-svg-icons";

export default class Icon {
    static get LEAD() {
        return faUserClock;
    }

    static get CUSTOMER() {
        return faUsersLine;
    }

    static get AGREEMENT() {
        return faHandshake;
    }

    static get TENDER() {
        return faCompassDrafting;
    }

    static get PROJECT() {
        return faCity;
    }

    static get TIMEREG() {
        return faClock;
    }

    static get INVOICE() {
        return faSackDollar;
    }

    static get SUPPLIER_INVOICE() {
        return faTruck;
    }

    static get DOCUMENT() {
        return faFolderOpen;
    }

    static get REPORT() {
        return faBook;
    }

    static get PAYMENT_PLAN() {
        return faCalendarDays;
    }

    static get QNA() {
        return faComments;
    }


    static get ADD() {
        return faAdd;
    }

    static get COPY() {
        return faCopy;
    }

    static get CREDIT() {
        return faSackXmark;
    }

    static get DISCONNECTED() {
        return faPlugCircleExclamation;
    }

    static get DOWNLOAD() {
        return faDownload;
    }

    static get EXCEL() {
        return faFileExcel;
    }

    static get INTEGRATION() {
        return faLink;
    }

    static get LOCK() {
        return faLock;
    }

    static get LOG() {
        return faBook;
    }

    static get MOVE() {
        return faArrowsUpDownLeftRight;
    }

    static get NOTARIZE() {
        return faPersonCircleCheck;
    }

    static get UNNOTARIZE() {
        return faCircleExclamation;
    }

    static get GENERATE() {
        return faMagicWandSparkles;
    }

    static get FILE() {
        return faFile;
    }

    static get PDF() {
        return faFilePdf;
    }

    static get IMAGE() {
        return faFileImage;
    }

    static get PLUGIN() {
        return faPuzzlePiece;
    }

    static get REMOVE() {
        return faTrash;
    }

    // static get REORDER() {
    //     return faBars;
    // }

    static get SEND() {
        return faEnvelope;
    }

    static get UNKNOWN() {
        return faQuestion;
    }

    static get UNLOCK() {
        return faUnlock
    }

    static get PREVIEW() {
        return faEye
    }

    static get INFO() {
        return faInfoCircle
    }

    static get BULK_UPDATE() {
        return faTasks
    }

    static get REMINDER() {
        return faBell
    }
}
import NetUtils from "../../utils/NetUtils";

const ArticleService = {
    findAll: () => NetUtils.doGet('/api/articles'),
    findById: id => NetUtils.doGet('/api/articles/' + id),
    update: model => NetUtils.doPost('/api/articles', model),

    bulkUpdatePrices: (
        id,
        startDate,
        endDate,
        updateTenderrent,
        updateProjectrentalbasis,
        updateProjectrent,
        updateTimeregarticle,
    ) => NetUtils.doPost('/api/articles/bulk-update-prices', {
        id: id,
        startDate: startDate,
        endDate: endDate,
        updateTenderrent: updateTenderrent,
        updateProjectrentalbasis: updateProjectrentalbasis,
        updateProjectrent: updateProjectrent,
        updateTimeregarticle: updateTimeregarticle
    })

}
export default ArticleService
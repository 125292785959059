import React, {useContext, useMemo, useState} from 'react';
import AppContext from "../../../../AppContext";
import CheckboxEditor from "../../../../components/Grid/CheckboxEditor";
import {addFreeRows, isFreeRow, numOfFreeRows} from "../../../../components/Grid/freeRows";
import Grid from "../../../../components/Grid/Grid";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import SelectEditor, {SubInfoItemRenderer} from "../../../../components/Grid/SelectEditor";
import AccessType from "../../../../enums/AccessType";
import useMountEffect from "../../../../hooks/useMountEffect";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findByField} from "../../../../utils/ArrayUtil";
import UserService from "../../../Users/UserService";

const NUM_OF_FREE_ROWS = 1

const QnafollowerGrid = props => {
    const context = useContext(AppContext)
    const {
        value,
        disabled,
        project,
        // ourReference
    } = props

    const rowList = disabled ? value : addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, name: '', email: ''
    })


    // const employees = useEmployees()
    const preload = usePreload()
    const [userList, setUserList] = useState([])
    const supplierList = useMemo(() => disabled ? [] : preload.getSupplierList(), [disabled, preload])
    const customerList = useMemo(() => disabled ? [] : preload.getCustomerList(), [disabled, preload])
    const subcontractorList = useMemo(() => supplierList.filter(item => item.subcontractor), [supplierList])

    useMountEffect(async () => {
        if (!disabled) setUserList(await UserService.findByCompany())
    })

    const customerId = project?.customer

    for (const row of rowList) {
        row.accessTypeName = AccessType.getName(row.accessType)
    }

    // const predefinedList = useMemo(() => {
    //     const result = []
    //     if (project) {
    //         if (ourReference && ourReference.id !== project.projectLeader) {
    //             if (!findByField(rowList, 'employee', project.projectLeader)) {
    //                 const projectLeader = employees.findById(project.projectLeader)
    //                 result.push({
    //                     employee: projectLeader.id,
    //                     type: 'EMPLOYEE',
    //                     name: i18n('project_leader') + ' - ' + projectLeader.name,
    //                     subInfo: projectLeader.subcontractorName,
    //                     email: projectLeader.email
    //                 })
    //             }
    //         }
    //
    //         const customer = findById(customerList, project.customer)
    //         if (customer.contactList.length <= 10) {
    //             for (const customercontact of customer.contactList) {
    //                 if (customercontact.email.length === 0) continue
    //                 if (findByField(rowList, 'customercontact', customercontact.id)) continue
    //
    //                 result.push({
    //                     type: 'CUSTOMER',
    //                     customercontact: customercontact.id,
    //                     name: i18n('customer') + ' - ' + customercontact.name,
    //                     subInfo: customer.name,
    //                     email: customercontact.email
    //                 })
    //             }
    //         }
    //     }
    //     return result
    // }, [rowList, employees, ourReference, customerList, project])

    const options = useMemo(() => {
        if (disabled) return []

        const result = []

        for (const user of userList) {
            if (user.email.length > 0) {
                // Do not add already added
                const usersInrowList = rowList.filter(item => item.accessType === AccessType.USER.id)
                if (findByField(usersInrowList, 'accessId', user.id)) continue

                result.push({
                    accessType: AccessType.USER.id,
                    accessId: user.id,
                    name: user.name,
                    email: user.email,
                    label: i18n('user') + ' - ' + user.name,
                    companyName: context.currentCompany.name,
                    filterBy: user.name + ';' +
                        context.currentCompany.name
                })
            }
        }

        // for (const employee of employees.activeUsable) {
        //     if (employee.email.length > 0) {
        //         // Do not add already added
        //         const employeesInrowList = rowList.filter(item => item.accessType === AccessType.EMPLOYEE.id)
        //         if (findByField(employeesInrowList, 'accessId', employee.id)) continue
        //
        //         result.push({
        //             accessType: AccessType.EMPLOYEE.id,
        //             accessId: employee.id,
        //             name: employee.name,
        //             email: employee.email,
        //             label: i18n('employee') + ' - ' + employee.name,
        //             subInfo: employee.subcontractorName,
        //             companyName: context.currentCompany.name,
        //             filterBy: employee.name + ';' +
        //                 employee.subcontractorName
        //         })
        //     }
        // }

        if (customerId > 0) {
            for (const c of customerList) {
                if (c.id === customerId) {
                    for (const cc of c.contactList) {
                        if (cc.email.length > 0) {
                            // Do not add already added
                            const customercontactsInrowList = rowList.filter(item => item.accessType === AccessType.CUSTOMERCONTACT.id)
                            if (findByField(customercontactsInrowList, 'accessId', cc.id)) continue

                            result.push({
                                accessType: AccessType.CUSTOMERCONTACT.id,
                                accessId: cc.id,
                                customercontactId: cc.id,
                                name: cc.name,
                                email: cc.email,
                                label: i18n('customer') + ' - ' + cc.name,
                                subInfo: c.name,
                                companyName: c.name,
                                filterBy: cc.name + ';' +
                                    c.name + ';' +
                                    cc.name + ';' +
                                    cc.email
                            })
                        }
                    }
                }
            }
        }

        for (const supplier of subcontractorList) {
            for (const suppliercontact of supplier.contactList) {
                if (suppliercontact.email.length > 0) {
                    // Do not add already added
                    const suppliercontactsInrowList = rowList.filter(item => item.accessType === AccessType.SUPPLIERCONTACT.id)
                    if (findByField(suppliercontactsInrowList, 'accessId', suppliercontact.id)) continue

                    result.push({
                        accessType: AccessType.SUPPLIERCONTACT.id,
                        accessId: suppliercontact.id,
                        name: suppliercontact.name,
                        email: suppliercontact.email,
                        label: i18n('subcontractor') + ' - ' + suppliercontact.name,
                        subInfo: supplier.name,
                        companyName: supplier.name,
                        filterBy: suppliercontact.name + ';' +
                            supplier.name + ';' +
                            suppliercontact.name + ';' +
                            suppliercontact.email
                    })
                }
            }
        }

        return result
    }, [rowList, userList, customerList, subcontractorList, customerId, disabled, context.currentCompany.name])

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = (list) => {
        props.onChange(getSlicedList(list));
    }
    const columns = [
        {
            field: 'name', headerI18n: 'follower',
            editor: e => <SelectEditor
                event={e}
                options={options}
                filterBy='filterBy'
                hideNoneOption={true}
                itemTemplate={item => {
                    return <SubInfoItemRenderer name={item.label} subInfo={item.subInfo}/>;
                }}
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => {
                if (!e.newValue?.accessType) return
                const _rowList = [...e.props.value]

                // const value = {
                //     id: e.newValue?.id,
                //     name: e.newValue.name,
                //     email: e.newValue.email
                // }

                // switch (e.newValue.type) {
                //     case AccessType.EMPLOYEE.id:
                //         value.accessType = AccessType.EMPLOYEE.id
                //         value.accessId = e.newValue.employeeId
                //         value.companyName = context.currentCompany.name
                //         break;
                //     case AccessType.CUSTOMERCONTACT:
                //         const customercontact = findById(customerList.flatMap(item => item.contactList), e.newValue.customercontactId)
                //         const customer = findById(customerList, customercontact.customer)
                //         value.accessType = AccessType.CUSTOMERCONTACT.id
                //         value.accessId = customercontact.id
                //         value.companyName = customer.name
                //         break;
                //     case AccessType.SUPPLIERCONTACT:
                //         const suppliercontact = findById(supplierList.flatMap(item => item.contactList), e.newValue.suppliercontactId)
                //         const supplier = findById(supplierList, suppliercontact.supplier)
                //         value.accessType = AccessType.SUPPLIERCONTACT.id
                //         value.accessId = suppliercontact.id
                //         value.companyName = supplier.name
                //         break;
                //     default:
                //         console.error("Invalid type: " + e.newValue.type)
                //         break;
                // }

                _rowList[e.rowIndex] = e.newValue
                _rowList[e.rowIndex].dirty = true
                fireChange(_rowList)
            },
        },
        {field: 'accessTypeName', headerI18n: 'type', width: 120},
        {field: 'companyName', headerI18n: 'company'},
        {field: 'email', headerI18n: 'email'},
        {
            header: i18n('only_read'),
            field: 'onlyRead',
            body: (rowData, e) =>
                isFreeRow(rowList, rowData) ||
                // (rowData.accessType !== AccessType.CUSTOMERCONTACT.id && rowData.accessType !== AccessType.SUPPLIERCONTACT.id)
                //     ? ''
                //     :
                CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange, disabled),
            rendererIsEditor: true,
            width: 80
        },
        {
            header: i18n('reminder'),
            field: 'reminder',
            body: (rowData, e) =>
                isFreeRow(rowList, rowData) ||
                CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange, disabled),
            rendererIsEditor: true,
            width: 80
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)), NUM_OF_FREE_ROWS, isVisible => !disabled)
    ]

    return <div className='formItem'>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={rowList}
              sortable={false}
              scrollable={false}
              onRowReorder={e => fireChange([...e.value])}
              footerColumnGroup={<div/>}
        />
        {/*{*/}
        {/*    !disabled && predefinedList?.length > 0 &&*/}
        {/*    <div style={{marginTop: '10px'}}>*/}
        {/*        <XlnzMenu model={predefinedList.map(item => ({*/}
        {/*                label: item.name,*/}
        {/*                command: () => {*/}
        {/*                    console.log("item", item)*/}
        {/*                    const _rowList = getSlicedList(rowList)*/}
        {/*                    _rowList.push({*/}
        {/*                        employee: item.employee,*/}
        {/*                        customercontact: item.customercontact,*/}
        {/*                        name: item.email,*/}
        {/*                        email: item.email*/}
        {/*                    })*/}
        {/*                    fireChange(_rowList)*/}
        {/*                }*/}
        {/*            })*/}
        {/*        )}*/}
        {/*                  ref={el => predefinedList.current = el}/>*/}
        {/*        <Button faicon={faPlus} onClick={e => predefinedList.current.toggle(e)}/>*/}
        {/*    </div>*/}
        {/*}*/}
        {
            // !disabled &&
            // <div style={{display: 'flex', alignItems: 'center', marginTop: '20px', color: '#777', fontStyle: 'italic'}}>
            //     <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: '16px'}}/>&nbsp;
            //     Vår referens {ourReference?.name} är alltid följare och behöver inte läggas till.
            // </div>
        }
    </div>
}

export default QnafollowerGrid;

import {hideLoading, showLoading} from "@components/Overlay/Loading";
import Spinner from "@components/Spinner/Spinner";
import React, {useCallback, useContext, useEffect, useState} from 'react';
import AppContext from "../../AppContext";
import {MainButton} from "../../components/Buttons/Button";
import OptionalDatePicker from "../../components/Date/OptionalDatePicker";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import {confirmYes} from "../../components/Overlay/Confirm";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";
import {initI18n} from "../../I18n/I18n";
import ArrayUtil from "../../utils/ArrayUtil";
import {formatDate, formatTime, todayDate} from "../../utils/DateUtils";
import Env from "../../utils/Env";
import NetUtils from "../../utils/NetUtils";
import ApiCalls from "./ApiCalls";
import AuthenticationCodes from "./AuthenticationCodes";
import EmployeeActivityGrid from "./EmployeeActivityGrid";
import ErrorLogs from "./ErrorLogs";
import FormStats from "./FormStats";
import Heap from "./Heap";
import LatestCronLogs from "./LatestCronLogs";
import OnlineEmployeesGrid from "./OnlineEmployeesGrid";
import OnlineUsersGrid from "./OnlineUsersGrid";
import './Superadmin.scss'
import SuperadminService from "./SuperadminService";
import Threads from "./Threads";
import UserActivityGrid from "./UserActivityGrid";

const Superadmin = () => {
    const context = useContext(AppContext)
    const [info, setInfo] = useState();
    // const [requestCounters, setRequestCounters] = useState();
    const [currentlyOnlineUsers, setCurrentlyOnlineUsers] = useState();
    const [currentlyOnlineEmployees, setCurrentlyOnlineEmployees] = useState();
    const [errorLogs, setErrorLogs] = useState();
    const [threads, setThreads] = useState();
    const [authenticationCodes, setAuthenticationCodes] = useState();
    const [formStats, setFormStats] = useState();
    const [userActivityList, setUserActivityList] = useState();
    const [employeeActivityList, setEmployeeActivityList] = useState();
    const [latestCronLogs, setLatestCronLogs] = useState();
    const [caches, setCaches] = useState();
    const [apiCalls, setApiCalls] = useState();
    const [scanningStats, setScanningStats] = useState();
    const [heap, setHeap] = useState();

    const [reloadTime, setReloadTime] = useState();

    const filter = useFilter('Superadmin', [{name: 'startDate', defaultValue: todayDate()}, {
        name: 'endDate',
        defaultValue: undefined
    },])

    const loadData = useCallback(async () => {
        showLoading()
        const [
            _info, _currentlyOnlineUsers, _currentlyOnlineEmployees, _errorLogs,
            _userActivityList, _employeeActivityList, _latestCronLogs, _caches, _apiCalls, _scanningStats,
            _threads, _authenticationCodes, _formStats,
            _heap
        ] =
            await Promise.all([
                SuperadminService.getInfo(),
                SuperadminService.getCurrentlyOnlineUsers(),
                SuperadminService.getCurrentlyOnlineEmployees(),
                SuperadminService.getErrorLogs(),
                SuperadminService.listUsersActivities(filter.startDate, filter.endDate),
                SuperadminService.listEmployeeActivities(filter.startDate, filter.endDate),
                SuperadminService.getLatestCronLogs(),
                SuperadminService.getCaches(),
                SuperadminService.getApiCalls(),
                SuperadminService.getScanningStats(filter.startDate, filter.endDate),
                SuperadminService.getThreads(),
                SuperadminService.getAuthenticationCodes(),
                SuperadminService.getFormStats(filter.startDate, filter.endDate),
                SuperadminService.getHeap()
            ])
        setInfo(_info)
        setCurrentlyOnlineUsers(_currentlyOnlineUsers)
        setCurrentlyOnlineEmployees(_currentlyOnlineEmployees)
        setErrorLogs(_errorLogs)
        setUserActivityList(ArrayUtil.sortOnField(_userActivityList, 'activeDuration').reverse())
        setEmployeeActivityList(ArrayUtil.sortOnField(_employeeActivityList, 'activeDuration').reverse())
        setLatestCronLogs(_latestCronLogs)
        setCaches(_caches)
        setApiCalls(_apiCalls)
        setThreads(_threads)
        setAuthenticationCodes(_authenticationCodes)
        setFormStats(_formStats)
        setScanningStats(_scanningStats)
        setHeap(_heap)

        setReloadTime(new Date().toISOString())
        hideLoading()
    }, [filter.startDate, filter.endDate])

    useEffect(() => {
        loadData();
    }, [loadData, filter.startDate, filter.endDate])


    const sync = (loaderFunc) => {
        confirmYes(async () => {
            showLoading()
            const result = await loaderFunc()
            if (result?.value?.length > 0) infoAlert(result.value)
            hideLoading()
        })
    }

    const fortnoxMenuItems = [
        {
            label: 'Get Access-Token',
            command: async () => infoAlert((await SuperadminService.fortnoxGetAccessToken()).value)
        },
        {
            label: 'Sync Invoice Accounts from Fortnox',
            command: () => sync(SuperadminService.fortnoxSyncInvoiceAccounts),
            hidden: Env.isProd()
        },
        {
            label: 'Sync Supplierinvoice Accounts from Fortnox',
            command: () => sync(SuperadminService.fortnoxSyncSupplierinvoiceAccounts),
            hidden: Env.isProd()
        },
        {
            label: 'Sync Customers from Fortnox', command: () => sync(SuperadminService.fortnoxSyncCustomers),
            hidden: Env.isProd()
        },
        {
            label: 'Sync Suppliers from Fortnox', command: () => sync(SuperadminService.fortnoxSyncSuppliers),
            hidden: Env.isProd()
        },
        {
            label: 'Sync Employees from Fortnox', command: () => sync(SuperadminService.fortnoxSyncEmployees),
            hidden: Env.isProd()
        }
    ].filter(item => !item.hidden)

    const pyramidMenuItems = [
        {
            label: 'Sync articles',
            command: async () => {
                showLoading()
                infoAlert((await SuperadminService.pyramidSyncArticles()).value)
                hideLoading()
            }
        },
        {
            label: 'Sync skipped',
            command: async () => {
                showLoading()
                infoAlert((await SuperadminService.pyramidSyncSkipped()).value)
                hideLoading()
            }
        },
    ].filter(item => !item.hidden)

    // const byggletMenuItems = [
    //     {
    //         label: 'Sync accounts',
    //         command: () => sync(SuperadminService.byggletSyncAccounts)
    //     },
    //     {
    //         label: 'Sync Timeregdebittypes',
    //         command: () => sync(SuperadminService.byggletSyncTimeregdebittypes)
    //     },
    //     {
    //         label: 'Sync Timeregsalarytypes',
    //         command: () => sync(SuperadminService.byggletSyncTimeregsalarytypes)
    //     },
    //     {
    //         label: 'Sync Articles',
    //         command: () => sync(SuperadminService.byggletSyncArticles)
    //     },
    //     {
    //         label: 'Sync Customers',
    //         command: () => sync(SuperadminService.byggletSyncCustomers)
    //     },
    //     {
    //         label: 'Sync Employees',
    //         command: () => sync(SuperadminService.byggletSyncEmployees)
    //     },
    //     {
    //         label: 'Sync Suppliers',
    //         command: () => sync(SuperadminService.byggletSyncSuppliers)
    //     },
    //     {
    //         label: 'Sync Tenderhowtotemplates',
    //         command: () => sync(SuperadminService.byggletSyncTenderhowtotemplates)
    //     },
    //     {
    //         label: 'Sync Projects',
    //         command: () => sync(SuperadminService.byggletSyncProjects)
    //     },
    //     {
    //         label: 'Sync assignments',
    //         command: () => sync(SuperadminService.byggletSyncAssignments)
    //     },
    //     {
    //         label: 'Sync supplierinvoices',
    //         command: () => sync(SuperadminService.byggletSyncSupplierinvoices)
    //     },
    //     {
    //         label: 'Sync supplierinvoice images',
    //         command: () => sync(SuperadminService.byggletSyncSupplierinvoiceImages)
    //     },
    //     {
    //         label: 'Sync invoices',
    //         command: () => sync(SuperadminService.byggletSyncInvoices)
    //     },
    //     {
    //         label: 'Sync invoice appendices',
    //         command: () => sync(SuperadminService.byggletSyncInvoiceAppendices)
    //     },
    // ]


    const devServerMenuItems = [
        // {
        //     label: '#### merge-scaffold-forms ####',
        //     command: () => sync(async () => {
        //         const result = await NetUtils.doGet('/api/superadmin/merge-scaffold-forms')
        //         return result
        //     })
        // },
        {
            label: 'Sync i18n',
            command: () => sync(async () => {
                const result = await SuperadminService.syncI18n()
                await initI18n(context.user.language)
                return result
            })
        },
        {
            label: 'Send Supplier Invoice Reminders',
            command: () => sync(async () => await SuperadminService.sendSupplierInvoiceReminders())
        },
    ]

    if (!currentlyOnlineUsers || !caches) return <Spinner/>
    return <div className="Superadmin">
        <div className="EditForm" style={{marginBottom: '40px'}}>
            <form>
                <div className="button-row" style={{marginLeft: '30px'}}>
                    <div className='left-buttons'>
                        <div className="formItem" style={{marginRight: '40px'}}>
                            <label>&nbsp;</label>
                            <MainButton label='Reload' onClick={() => loadData()}/>
                        </div>
                        <div className="formItem" style={{width: '160px'}}>
                            <label>Start Date</label>
                            <OptionalDatePicker value={filter.startDate} onChange={date => {
                                filter.update('startDate', date)
                            }}/>
                        </div>
                        <div className="formItem" style={{width: '160px'}}>
                            <label>End Date</label>
                            <OptionalDatePicker value={filter.endDate} onChange={date => {
                                filter.update('endDate', date)
                            }}/>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        <ExtraMenu label='&nbsp;&nbsp;Fortnox' itemList={fortnoxMenuItems}/>
                        <ExtraMenu label='&nbsp;&nbsp;Pyramid' itemList={pyramidMenuItems}/>
                        {/*{(Env.isDev()) && <ExtraMenu label='&nbsp;&nbsp;Bygglet' itemList={byggletMenuItems}/>}*/}
                        {(Env.isDev()) && <ExtraMenu label='&nbsp;&nbsp;Dev Server' itemList={devServerMenuItems}/>}
                        {/*{<ExtraMenu label='&nbsp;&nbsp;Dev Server' itemList={devServerMenuItems}/>}*/}

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MainButton label='ValidateSSN'
                                    onClick={async () => {
                                        showLoading()
                                        const ssnList = await SuperadminService.validateSsn()

                                        let msg = 'INVALID SSNs:\n'
                                        ssnList.forEach(item => {
                                            msg += item.companyName + ': ' + item.customerName + ', ' + item.ssn + '\n'
                                        })

                                        infoAlert(msg)
                                        hideLoading()
                                    }}/>

                        {/*&nbsp;&nbsp;&nbsp;&nbsp;*/}
                        {/*<MainButton label='FIX BOREALIS'*/}
                        {/*            onClick={async () => {*/}
                        {/*                confirmYes(async () => {*/}
                        {/*                    showLoading()*/}
                        {/*                    await NetUtils.doGet('/api/superadmin/fix-borealis-to-sub-Project')*/}
                        {/*                    hideLoading()*/}
                        {/*                })*/}
                        {/*            }}/>*/}
                    </div>
                </div>
                <div className="button-row" style={{marginBottom: '30px', marginLeft: '30px'}}>

                    <div style={{paddingTop: '15px', fontSize: '12px'}}>
                        <table>
                            <tbody>
                            <tr>
                                <td>Server start time:</td>
                                <td>{formatTime(info.serverStartTime)}</td>
                            </tr>
                            <tr>
                                <td>Last reload:</td>
                                <td>{formatTime(reloadTime)}</td>
                            </tr>
                            </tbody>
                            {
                                info.certificateInfoList?.length > 0 &&
                                <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign: 'left'}}>Domän</th>
                                    <th style={{textAlign: 'right'}}>Last Check</th>
                                    <th style={{textAlign: 'right'}}>Expire</th>
                                </tr>
                                {
                                    info.certificateInfoList.map(item => <tr key={item.domain}>
                                        <td>{item.domain}</td>
                                        <td style={{textAlign: 'right'}}>{item.lastCheck ? formatDate(item.lastCheck) : ''}</td>
                                        <td style={{textAlign: 'right'}}>{item.expire ? formatDate(item.expire) : ''}</td>
                                    </tr>)
                                }
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </form>

        </div>

        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <XlnzCard title="Currently online">
                <OnlineUsersGrid value={currentlyOnlineUsers}/>

                {
                    currentlyOnlineEmployees?.length > 0 &&
                    <div className="Grid" style={{marginTop: '50px'}}>
                        <OnlineEmployeesGrid value={currentlyOnlineEmployees}/>
                    </div>
                }
            </XlnzCard>

            <XlnzCard title='Activity'>
                <div className="Grid">
                    <UserActivityGrid value={userActivityList}/>
                    {employeeActivityList?.length > 0 && <div style={{marginTop: '50px'}}>
                        <EmployeeActivityGrid value={employeeActivityList}/>
                    </div>}
                </div>
            </XlnzCard>

            <ErrorLogs value={errorLogs} onReload={loadData}/>

            <XlnzCard title='Latest Cron Logs' style={{width: '950px'}}>
                <div className="Grid">
                    <LatestCronLogs value={latestCronLogs}/>
                </div>
            </XlnzCard>

            <XlnzCard title='Supplier Invoice Scanning Stats' style={{width: '450px'}}>
                <div className="Grid">
                    <Grid
                        scrollable={false}
                        columns={[{field: 'name', header: 'Company'}, {
                            field: 'num',
                            header: 'Quantity',
                            width: 60,
                            align: 'right',
                            body: GridTemplates.integer,
                            footer: FooterAggregate.SUM_INTEGER
                        },]}
                        value={scanningStats}
                        onRowSelect={() => {
                        }}
                        forceDesktop={true}
                    /></div>
            </XlnzCard>

            <AuthenticationCodes value={authenticationCodes}/>
            <FormStats value={formStats}/>
            <ApiCalls value={apiCalls}/>

            <Heap value={heap}/>
            <Threads value={threads}/>

            <XlnzCard title='User Cache' style={{width: '750px'}}>
                <div className="Grid">
                    <Grid
                        scrollable={false}
                        columns={[
                            {field: 'id', header: 'ID', width: 80},
                            {field: 'name', header: 'Name'},
                            {field: 'email', header: 'E-mail'},

                        ]}
                        value={caches.userList}
                        onRowSelect={() => {
                        }}
                        forceDesktop={true}
                    />
                </div>
            </XlnzCard>

            <XlnzCard title='Company Cache' style={{width: '750px'}}>
                <div className="Grid">
                    <Grid
                        scrollable={false}
                        columns={[
                            {field: 'id', header: 'ID', width: 80},
                            {field: 'label', header: 'Name'},
                            {field: 'regNo', header: 'Reg.No'},

                        ]}
                        value={caches.companyList}
                        onRowSelect={() => {
                        }}
                        forceDesktop={true}
                    />
                </div>
            </XlnzCard>
        </div>
    </div>
}

export default Superadmin;

import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useMemo, useState} from 'react';
import {useForm} from "react-hook-form";
import AppContext from "../../../../AppContext";
import AppendixViewer from "../../../../components/Appendix/AppendixViewer";
import Button, {CancelButton, SendButton} from "../../../../components/Buttons/Button";
import FormItemAppendixGrid, {Thumbnail} from "../../../../components/EditForm/FormItemAppendixGrid";
import FormItemTextArea from "../../../../components/EditForm/FormItemTextArea";
import Grid from "../../../../components/Grid/Grid";
import {confirm} from "../../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import AccessType from "../../../../enums/AccessType";
import Role from "../../../../enums/Role";
import {GridTemplates} from "../../../../GridTemplates";
import useRoles from "../../../../hooks/useRoles";
import {i18n} from "../../../../I18n/I18n";

// IMPORTANT: This file is shared among multiple modules, supplier, customer etc.
const QnamessageGrid = props => {
    const context = useContext(AppContext)
    const {
        value,
        getValues,
        reset,
        disabled: _disabled,
        dialog,
        handleSubmit,
        save,
        loadData,
        insertMessage
    } = props

    const roles = useRoles()

    function findQnaFollower(followerList, accessType, accessId) {
        const followerListWithAccessType = followerList.filter(item =>
            item.accessType === accessType &&
            item.accessId === accessId
        )
        return followerListWithAccessType.length > 0 ? followerListWithAccessType[0] : undefined
    }

    const disabled = useMemo(() => {
        if (_disabled) return _disabled

        const followerList = getValues('followerList')

        switch (context.currentCompany.accessType) {
            case AccessType.CUSTOMERCONTACT.id:
                // const customercontactFollower = findByField(followerList, 'customercontact', context.currentCompany.accessId)
                const customercontactFollower = findQnaFollower(followerList, AccessType.CUSTOMERCONTACT.id, context.currentCompany.accessId)
                return customercontactFollower?.onlyRead
            case AccessType.SUPPLIERCONTACT.id:
                const suppliercontactFollower = findQnaFollower(followerList, AccessType.SUPPLIERCONTACT.id, context.currentCompany.accessId)
                return suppliercontactFollower?.onlyRead
            default:
                break
        }
        return false
    }, [getValues, _disabled, context.currentCompany])

    // const fireChange = (list) => {
    // props.onChange(getSlicedList(list));
    // }
    const columns = [
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, width: 140},
        {field: 'senderName', headerI18n: 'sender', width: 160},
        {
            field: 'message', headerI18n: 'message', body: row => {

                const appendencies = row.appendixList.map(appendix =>
                    <div key={appendix.id}
                         style={{
                             width: '160px',
                             display: 'flex',
                             flexDirection: 'column',
                             justifyContent: 'flex-start',
                             alignItems: 'center',
                             // gap: '30px'
                         }}>
                        <span style={{color: '#777'}}>{appendix.name}</span>
                        <Thumbnail appendix={appendix}
                                   onClick={e => {
                                       e.stopPropagation()
                                       renderOverlay(<AppendixViewer
                                           imageList={row.appendixList}
                                           index={row.appendixList.indexOf(appendix)}
                                           onReload={() => {
                                               loadData()
                                           }}
                                           disabled={disabled}
                                       />)
                                   }}/>
                    </div>
                )

                return <div style={{width: '100%'}}>
                    <span
                        style={{fontWeight: 'bold', whiteSpace: 'pre-line'}}>{row.message.replace(/\n\n/g, '\n')}</span>
                    {
                        row.appendixList.length > 0 &&
                        <>
                            {/*<hr style={{width: '100%', borderColor: 'green', borderWidth: '0.5px'}}/>*/}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '10px',
                                flexWrap: 'wrap',
                                gap: '10px'
                            }}>
                                {appendencies}
                            </div>
                        </>
                    }
                </div>
            }
        },
    ]

    return <div className='formItem'>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={value}
              sortable={false}
              scrollable={false}
        />
        {
            !disabled &&
            <div style={{marginTop: '15px'}}>
                <Button faicon={faPlus}
                        label={i18n('new_message') + '/' + i18n('do_answer').toLowerCase()}
                        onClick={handleSubmit(data => {
                            const followerList = getValues('followerList')
                            if (followerList?.length === 0 && roles.hasRole(Role.PROJECT_WRITE)) {
                                confirm({
                                    header: i18n('question'),
                                    message: 'Vill du skriva ett meddelande utan följare?',
                                    options: [
                                        {
                                            label: 'Ja',
                                            onClick: () => showMessageDialog()
                                        }
                                    ]
                                })
                                return
                            } else {
                                showMessageDialog()
                            }

                            async function showMessageDialog() {
                                // if (!data.id) {
                                if (!disabled) {
                                    showLoading()
                                    await save(data)
                                    hideLoading()
                                }
                                dialog.show(<AddMessageDialog
                                    onSend={async (message, files) => {
                                        showLoading()
                                        const qnaId = getValues('id')
                                        console.log("SAVE MODEL:", data)
                                        const _qna = await insertMessage(qnaId, message, files)
                                        reset(_qna)
                                        hideLoading()
                                        // await loadData()
                                    }}
                                    disabled={disabled}
                                />);
                            }
                        })}
                        style={{backgroundColor: '#699b2c', color: '#fff'}}
                />
            </div>
        }
    </div>
}

export default QnamessageGrid;


function AddMessageDialog({message, onSend, onHide, disabled}) {
    const [fileList, setFileList] = useState([])

    const defaultValues = {
        message: message,
        appendixList: []
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {formState, handleSubmit, reset} = form

    const onSubmit = data => {
        // delete data.appendixList

        onSend(data.message, fileList)
        onHide()
    }

    const save = async (data, files) => {
        if (!disabled) {
            console.log("TEMP SAVE MODEL:", data)

            for (const file of files) {
                // console.log("FILE:", file, URL.createObjectURL(file))

                data.appendixList.push({
                    id: 0,
                    qnamessage: 2,
                    name: file.name,
                    fileSize: file.size,
                    url: URL.createObjectURL(file),
                })
            }
            setFileList([...fileList, ...files])
            reset(data)
        } else {
            // return model;
        }
    }
    return <XlnzDialog header={i18n('new_message') + '/' + i18n('do_answer').toLowerCase()}
                       onHide={onHide}
                       buttons={() => [
                           <SendButton key='send' labelI18n='send'
                                       disabled={formState.isSubmitting}
                                       onClick={handleSubmit(onSubmit)}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className='EditForm' style={{margin: '0'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormItemTextArea name='message' labelI18n='message' required {...form}/>

                <FormItemAppendixGrid name='appendixList' save={save} hideAttach={true} {...form}/>
            </form>
        </div>
    </XlnzDialog>
}

import React, {useMemo} from 'react';
import {addFreeRows} from "../../../../components/Grid/freeRows";
import Grid from "../../../../components/Grid/Grid";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import SelectEditor, {SubInfoItemRenderer} from "../../../../components/Grid/SelectEditor";
import usePreload from "../../../../hooks/usePreload";
import {findById, sortByField} from "../../../../utils/ArrayUtil";

const NUM_OF_FREE_ROWS = 1

const CustomerAccessGrid = props => {
    const {
        value,
        disabled,
        onChange,
        customerId
    } = props

    const preload = usePreload()
    const customerList = preload.getCustomerList()

    const options = useMemo(() => {
        const result = []

        if (customerId > 0) {
            for (const c of customerList) {
                if (c.id === customerId) {
                    for (const cc of c.contactList) {
                        if (cc.email.length > 0) {
                            // Do not add already added
                            if (value.includes(cc.id)) continue

                            result.push({
                                id: cc.id,
                                name: cc.name,
                                customerName: c.name,
                                email: cc.email,
                                filterBy: cc.name + ';' +
                                    c.name + ';' +
                                    cc.name + ';' +
                                    cc.email
                            })
                        }
                    }
                }
            }
        }

        sortByField(result, 'name')
        return result
    }, [customerList, value, customerId])

    const fireChange = (list) => {
        let result = list
            .filter(item => item.id > 0)
            .map(item => item.id)
        // console.log("result", result, list)
        onChange(result)
    }
    const columns = [
        {
            field: 'name', headerI18n: 'contact_person',
            editor: e => <SelectEditor
                event={e}
                options={options}
                filterBy='filterBy'
                hideNoneOption={true}
                itemTemplate={item => {
                    return <SubInfoItemRenderer name={item.name} subInfo={item.email}/>;
                }}
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => {
                if (!e.newValue) return
                const _rowList = [...e.props.value]

                const value = {
                    id: e.newValue?.id,
                    name: e.newValue.name,
                    email: e.newValue.email
                }

                _rowList[e.rowIndex] = value
                _rowList[e.rowIndex].dirty = true
                fireChange(_rowList)
            },
        },
        // {field: 'email', headerI18n: 'email'},
        RemoveColumn(row => onChange(value.filter(id => id !== row.id)), NUM_OF_FREE_ROWS)
    ]

    const rowList = useMemo(() => {
        let result = value.map(id => {
            const customercontact = findById(customerList.flatMap(item => item.contactList), id);
            const customer = findById(customerList, customercontact.customer)

            return {
                id: customercontact.id,
                name: customercontact.name,
                email: customercontact.email,
                customerName: customer.name
            }
        })
        if (!disabled && options.length > 0)
            result = addFreeRows(result, NUM_OF_FREE_ROWS, {id: 0})
        return result
    }, [customerList, disabled, value, options])


    return <div className='formItem' style={{width: '350px'}}>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={rowList}
            // value={getRowList(value)}
              sortable={false}
              scrollable={false}
              onRowReorder={e => fireChange([...e.value])}
              footerColumnGroup={<div/>}
        />
    </div>
}

export default CustomerAccessGrid;

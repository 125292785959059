import React from 'react'

const AppContext = React.createContext({
    user: {
        id: 0,
        ctime: "2022-01-01T00:00:00Z",
        name: "",
        email: "",
        lastLoginTime: "2022-01-01T00:00:00Z",
        active: true,
    },
    companies: {
        accessType: '',
        accessId: 0,
        accessName: '',
        id: 0,
        name: '',
    },
    currentCompany: undefined,
})

export default AppContext
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useMemo} from 'react';
import {Controller} from "react-hook-form";
import Icon from "../../enums/Icon";
import {GridTemplates} from "../../GridTemplates";
import NetUtils from "../../utils/NetUtils";
import AppendixViewer from "../Appendix/AppendixViewer";
import FileUpload from "../FileUpload/FileUpload";
import CheckboxEditor from "../Grid/CheckboxEditor";
import Grid from "../Grid/Grid";
import RemoveColumn from "../Grid/RemoveColumn";
import {hideLoading, showLoading} from "../Overlay/Loading";
import renderOverlay from "../Overlay/renderOverlay";

const FormItemAppendixGrid = props => {
    const {
        control,
        disabled,
        name,
        save,

        getValues,
        trigger,
        reset,
        hideAttach,
        documentSupport,
        getCustomHeaderItems,
        showToEmployees,
    } = props

    return <Controller name={name}
                       control={control}
                       render={({field: {ref, value, onChange}}) =>
                           <AppendixGrid inputRef={ref} value={value} onChange={onChange}
                                         disabled={disabled}
                                         save={save}
                                         getValues={getValues}
                                         trigger={trigger}
                                         reset={reset}
                                         hideAttach={hideAttach}
                                         documentSupport={documentSupport}
                                         getCustomHeaderItems={getCustomHeaderItems}
                                         showToEmployees={showToEmployees}
                           />
                       }/>
}
export default FormItemAppendixGrid;


export const AppendixGrid = props => {
    const {
        value,
        onChange,
        disabled,
        getValues,
        trigger,
        reset,
        save,
        hideAttach,
        documentSupport,
        getCustomHeaderItems,
        showToEmployees
    } = props

    const fireChange = (list) => {
        onChange(list);
    }

    const columns = [
        {
            field: 'thumbnail',
            width: 140,
            body: row => <Thumbnail appendix={row} onClick={e => {
                e.stopPropagation()
                renderOverlay(<AppendixViewer
                    imageList={value}
                    index={value.indexOf(row)}
                    onReload={fireChange}
                />)
            }}/>
        },
        {field: 'name', headerI18n: 'name'},
        {
            field: 'attach',
            headerI18n: 'attach',
            body: (rowData, e) => CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange),
            rendererIsEditor: true,
            width: 50,
            align: 'center',
            hide: hideAttach
        },
        {
            field: 'showToEmployees',
            headerI18n: 'show_to_employees',
            body: (rowData, e) => CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange),
            rendererIsEditor: true,
            width: 110,
            align: 'center',
            hide: !showToEmployees
        },
        {field: 'fileSize', headerI18n: 'size', width: 100, align: 'right', body: GridTemplates.formatBytes},
        RemoveColumn(row => fireChange(value.filter(r => r !== row)), 0, () => !disabled)
    ].filter(item => !item.hide)

    return <div className='formItem' style={{width: '900px'}}>
        <div style={{marginBottom: '0px'}}>
            <Grid
                columns={columns}
                value={value || []}
                hideFooter={true}
                footerColumnGroup={<div/>}
                onRowReorder={e => onChange([...e.value])}
                scrollable={false}
                disabled={disabled}
            />
        </div>
        {
            !disabled &&
            <div className="formItem">
                <FileUpload multiple={true}
                            getValues={getValues}
                            trigger={trigger}
                            reset={reset}
                            save={save}
                            accept='application/pdf,image/jpeg,image/png,image/heic'
                            documentSupport={documentSupport}
                            getCustomHeaderItems={getCustomHeaderItems}
                />
            </div>
        }
    </div>
}

export const Thumbnail = ({appendix, onClick}) => {
    const indexOfDot = appendix.name.lastIndexOf('.')
    const ext = appendix.name.substring(indexOfDot + 1).toLowerCase();
    const isPdf = ext === 'pdf'
    const isImage = ext === 'jpg' || ext === 'jpeg' || ext === 'png'
    const isUnknown = !isPdf && !isImage

    const onDownload = async e => {
        e.stopPropagation()
        showLoading()
        await NetUtils.downloadFile('/api/files/' + appendix.token)
        hideLoading()
    }

    function getImageUrl() {
        if (appendix.url) {
            return appendix.url
        } else {
            return '/api/files/thumbnail/' + appendix.token + (appendix.hash ? '#' + appendix.hash : '')
        }
    }

    const icon = useMemo(() => {
        if (ext.includes(
            "xlsx",  // Nyare Excel-format
            "xls",   // Äldre Excel-format
            "xlsm",  // Excel med makron
            "xltx",  // Mall utan makron
            "xltm",  // Mall med makron
            "csv",   // Kommaavgränsad textfil
            "xlsb"   // Binärt Excel-format
        )) {
            return faFileExcel
        }

        return Icon.FILE
    }, [ext])

    return <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: '5px',
        cursor: 'pointer',
    }}>
        {
            isPdf &&
            <FontAwesomeIcon icon={Icon.PDF}
                             style={{fontSize: '32px', opacity: '75%'}}
                             onClick={onClick}
            />
        }
        {
            isImage &&
            <img src={getImageUrl()}
                 alt=''
                 style={{maxWidth: '128px', maxHeight: '128px'}}
                 onClick={onClick}
            />
        }
        {
            isUnknown &&
            <FontAwesomeIcon icon={icon}
                             style={{fontSize: '32px', opacity: '75%'}}
                             onClick={onDownload}
            />
        }
    </div>
}
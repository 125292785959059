import AccessType from "./enums/AccessType";
import Companyprop from "./enums/Companyprop";
import {findById, sortByField} from "./utils/ArrayUtil";
import LocalStorageUtils from "./utils/LocalStorageUtils";
import SessionStorageUtils from "./utils/SessionStorageUtils";

export default class Auth {
    // Given by server
    user
    customercontactList
    suppliercontactList
    companyList

    // Evaluated
    currentCompany
    accessType
    accessId

    constructor(loginInfo) {
        this.user = loginInfo.user
        this.customercontactList = loginInfo.customercontactList
        this.suppliercontactList = loginInfo.suppliercontactList
        this.companyList = loginInfo.companyList
    }

    get evaluate() {
        let credentials = null
        const sessionStorageCredentials = new SessionStorageCredentials()
        const localStorageCredentials = new LocalStorageCredentials()

        // 1. Primarily try the get from session storage.
        if (sessionStorageCredentials.isValid()) credentials = sessionStorageCredentials.credentials

        // 2. Secondarily trye to get from local storage.
        if (!credentials) {
            const localStorageCredentials = new LocalStorageCredentials()
            if (localStorageCredentials.isValid()) credentials = localStorageCredentials.credentials
        }

        // 3. Fallback to find what kind of access type that is authenticaed.
        if (!credentials) {
            credentials = this.#getDefaultCredentials()
        }

        // console.log("Credentials", credentials)

        this.accessUser = this.#getAccessUser(credentials)

        // In case an access is removed, this can happen.
        if (!this.accessUser) {
            sessionStorageCredentials.clear()
            localStorageCredentials.clear()
            credentials = this.#getDefaultCredentials()
            this.accessUser = this.#getAccessUser(credentials)
        }

        this.currentCompany = findById(this.companyList.filter(c => c.accessType === credentials.accessType), credentials.company)
        this.accessType = credentials.accessType
        this.accessId = credentials.accessId

        sessionStorageCredentials.save(credentials)
        localStorageCredentials.save(credentials)

        return {
            user: this.accessUser,
            companies: this.companyList,
            currentCompany: this.currentCompany,
            companyAccessType: this.accessType,
            companyAccessId: this.accessId
        }
    }

    #getAccessUser(credentials) {
        switch (credentials.accessType) {
            case AccessType.USER.id:
                return this.user
            case AccessType.CUSTOMERCONTACT.id:
                return findById(this.customercontactList, credentials.accessId)
            case AccessType.SUPPLIERCONTACT.id:
                return findById(this.suppliercontactList, credentials.accessId)
            default:
                throw new Error("Invalid access type: " + credentials.accessType)
        }
    }

    #getDefaultCredentials() {
        // 3a. Try to find a user.
        if (this.user) {
            return new Credentials(
                this.#getDefaultCompany(AccessType.USER.id).id,
                AccessType.USER.id,
                this.user.id
            )
        }

        // 3c. Try to find a suppliercontact.
        if (this.customercontactList?.length > 0) {
            const customercontact = this.customercontactList[0]
            return new Credentials(
                customercontact.company,
                AccessType.CUSTOMERCONTACT.id,
                customercontact.id
            )
        }

        // 3c. Try to find a suppliercontact.
        if (this.suppliercontactList?.length > 0) {
            const suppliercontact = this.suppliercontactList[0]
            return new Credentials(
                suppliercontact.company,
                AccessType.SUPPLIERCONTACT.id,
                suppliercontact.id
            )
        }
    }

    #getDefaultCompany(accessType) {
        const _companyList = this.companyList.filter(c => c.accessType === accessType)
        _companyList.forEach(c => {
            c.priority = Number(c.propList.find(p => p.name === Companyprop.PRIORITY.name)?.value) || 0
        })
        sortByField(_companyList, 'priority').reverse()
        return _companyList[0]
    }
}


export class Credentials {
    constructor(company, accessType, accessId) {
        this.company = company
        this.accessType = accessType
        this.accessId = accessId
    }

    isValid() {
        return !!(this.company > 0 && this.accessType && this.accessId > 0)
    }
}

class StorageCredentials {
    constructor(storageUtils) {
        this.storageUtils = storageUtils
        this.credentials = new Credentials(
            Number(storageUtils.getItem('company')),
            AccessType.findById(storageUtils.getItem('companyAccessType'))?.id,
            Number(storageUtils.getItem('companyAccessId'))
        )
    }

    isValid() {
        return this.credentials.isValid()
    }

    save(credentials) {
        this.storageUtils.setItem("company", credentials.company)
        this.storageUtils.setItem("companyAccessType", credentials.accessType)
        this.storageUtils.setItem("companyAccessId", credentials.accessId)
    }

    clear() {
        this.storageUtils.removeItem("company")
        this.storageUtils.removeItem("companyAccessType")
        this.storageUtils.removeItem("companyAccessId")
    }
}

export class SessionStorageCredentials extends StorageCredentials {
    constructor() {
        super(SessionStorageUtils)
    }
}

export class LocalStorageCredentials extends StorageCredentials {
    constructor() {
        super(LocalStorageUtils)
    }
}
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useState} from 'react';
import {Controller} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import Button, {CopyButton, GenerateButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemText from "../../../components/EditForm/FormItemText";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import TabPanel from "../../../components/TabView/TabPanel";
import Role from "../../../enums/Role";
import {i18n} from "../../../I18n/I18n";
import {todayDate} from "../../../utils/DateUtils";
import RouterUtils from "../../../utils/RouterUtils";
import FormanswerEditForm from "../../Formanswers/edit/FormanswerEditForm";
import FormService from "../FormService";
import FormrowGrid from "./FormrowGrid";
import FormsectionGrid from "./FormsectionGrid";

const FormEditForm = props => {
    const context = useContext(AppContext)
    const navigate = useNavigate()
    const [form, setForm] = useState()
    const location = useLocation()

    function postLoadFunction(model) {
        model.targetCompany = model.company
    }


    function preSaveFunction(data) {
        delete data.company
        data.company = data.targetCompany

        if (data.key.length === 0) delete data.key
    }

    async function downloadPdf(item) {
        preSaveFunction(item)
        const savedForm = await FormService.update(item)
        const newModel = {
            id: 0,
            company: context.currentCompany.id,
            form: savedForm,
            rowList: [],
            sectionList: []
        }

        renderOverlay(<FormanswerEditForm
            model={newModel}
            // onSave={onSave}
            defaultFormValues={{
                '$date': todayDate(),
                '$companyCity': context.currentCompany.address.city,
                '$projectAddressString': 'projectAddressString',
                '$customercontactName': 'customercontactName'
            }}
            preview={true}
        />)
    }

    function generateJson(item) {
        confirmYes(async () => {
            showLoading()
            preSaveFunction(item)
            const savedForm = await FormService.update(item)
            await FormService.generate(savedForm.id)
            RouterUtils.goBack(navigate)
            hideLoading()
        })
    }

    // function readJson(item) {
    //     confirmYes(async () => {
    //         showLoading()
    //         preSaveFunction(item)
    //         const savedForm = await FormService.update(item)
    //         await FormService.generate(savedForm.id)
    //         RouterUtils.goBack(navigate)
    //         hideLoading()
    //     })
    // }

    function copyJson(item) {
        delete item.flattenRowList

        // item.id = 0
        delete item.id

        item.rowList.forEach(row => {
            row.id = 0
            row.form = 0
        })

        item.sectionList.forEach(section => {
            section.id = 0
            section.form = 0

            section.rowList.forEach(sectionrow => {
                sectionrow.id = 0
                sectionrow.formsection = 0
            })
        })

        navigator.clipboard.writeText(JSON.stringify(item, null, 2));

        console.log("item", item)
    }

    async function readJson() {

        const text = await navigator.clipboard.readText();
        const obj = JSON.parse(text)
        obj.id = RouterUtils.getId(location)

        form.reset(obj)

        console.log("obj", obj)
    }

    return <SimpleEditForm
        writeRole={Role.SUPERADMIN}
        headlineI18n='form'
        style={{
            width: '1600px'
        }}
        defaultValues={{
            children: [],
            active: true,
            rowList: [],
            sectionList: []
        }}
        onFormCreated={setForm}
        loadFunction={FormService.findById}
        postLoadFunction={postLoadFunction}
        preSaveFunction={preSaveFunction}
        saveFunction={FormService.update}
        removeFunction={FormService.delete}

        initRightButtons={form => [
            <CopyButton key='copy' label='Kopiera JSON' onClick={e => form.handleSubmit(copyJson)()}/>,
            <GenerateButton key='read' label='Läs in JSON' onClick={e => readJson()}/>,
            <GenerateButton key='generate' labelI18n='generate' onClick={e => form.handleSubmit(generateJson)()}/>,
            <Button key='preview' faicon={faMagnifyingGlass} labelI18n='preview'
                    onClick={e => form.handleSubmit(downloadPdf)()}/>
        ]}

        initLeftFields={form => [
            <FormItemDropdown key='targetCompany' name='targetCompany' labelI18n='company'
                              options={context.companies}
                              showSelect={true}
                              {...form}
                              disabled={form.getValues('id') > 0}
            />,
            <FormItemText key='name' name='name' labelI18n='name' maxLength={200} required {...form}/>,
        ]}
        initCenterFields={form => [
            <FormItemText key='key' name='key' labelI18n='key' capitalize={false}
                          maxLength={500} {...form}/>,

            <div key='Variables' style={{fontSize: '12px', marginTop: '30px'}}>
                <b>Variables:</b><br/>
                <div>$customercontactName</div>
                <div>$projectAddressString</div>
            </div>
        ]}
        initRightFields={form => [
            <FormItemCheckbox key='template' name='template' labelI18n='template' {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        initTabs={form => {
            const id = form.getValues('id')
            const children = form.getValues('children')

            return [
                <TabPanel key='rowList' header={i18n('rows')}>
                    <Controller name="rowList"
                                control={form.control}
                                render={({field: {ref, value, onChange}}) =>
                                    <FormrowGrid inputRef={ref} value={value} onChange={onChange}
                                                 children={children}
                                    />
                                }/>
                </TabPanel>,
                <TabPanel key='sectionList' header={i18n('sections')}>
                    <Controller name="sectionList"
                                control={form.control}
                                render={({field: {ref, value, onChange}}) =>
                                    <FormsectionGrid inputRef={ref} value={value} onChange={onChange}
                                                     parentId={id}
                                    />
                                }/>
                </TabPanel>
            ];
        }}
    />
}

export default FormEditForm;

import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import usePreload from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import RouterUtils from "../../utils/RouterUtils";
import {CancelButton, RemoveButton, SaveButton} from "../Buttons/Button";
import {errorAlert} from "../Overlay/Alert";
import {confirmYes} from "../Overlay/Confirm";
import {hideLoading, showLoading} from "../Overlay/Loading";
import Spinner from "../Spinner/Spinner";
import TabView from "../TabView/TabView";

const SimpleEditForm = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()

    const {
        disabled,
        writeRole,
        companyId = props.legalCompany ? (context.currentCompany.parentId || context.currentCompany.id) : context.currentCompany.id,
        headline,
        headlineI18n,
        defaultValues,
        onFormCreated,
        preLoadFunction,
        loadFunction,
        postLoadFunction,
        preSaveFunction,
        postSaveFunction, //REMOVE!!
        saveFunction,
        removeFunction,
        initRightButtons,
        initLeftFields,
        initCenterFields,
        initRightFields,
        initTabs,
        preloadType,
        style
    } = props
    const [loading, setLoading] = useState(true)
    const _disabled = disabled || !roles.hasRole(writeRole);

    const location = useLocation()
    const navigate = useNavigate()
    const id = RouterUtils.getId(location)

    // const form = {...useForm({defaultValues: defaultValues}), disabled: _disabled}
    const form = useForm({defaultValues: defaultValues})
    form.disabled = disabled
    const {handleSubmit, reset, formState: {isSubmitting}, getValues} = form


    // const prevValues = useRef({});
    // useEffect(() => {
    //     const changedValues = [];
    //
    //     // Check if any of the dependencies have changed by comparing with previous values
    //     if (prevValues.current.reset !== reset) changedValues.push('reset');
    //     if (prevValues.current.id !== id) changedValues.push('id');
    //     if (prevValues.current.defaultValues !== defaultValues) changedValues.push('defaultValues');
    //     if (prevValues.current.form !== form) changedValues.push('form');
    //     if (prevValues.current.loadFunction !== loadFunction) changedValues.push('loadFunction');
    //     if (prevValues.current.onFormCreated !== onFormCreated) changedValues.push('onFormCreated');
    //     if (prevValues.current.postLoadFunction !== postLoadFunction) changedValues.push('postLoadFunction');
    //     if (prevValues.current.preLoadFunction !== preLoadFunction) changedValues.push('preLoadFunction');
    //
    //     if (changedValues.length > 0) {
    //         console.log('Changed values:', changedValues);
    //     }
    //
    //     // Update the ref with current values
    //     prevValues.current = { reset, id, defaultValues, form, loadFunction, onFormCreated, postLoadFunction, preLoadFunction };
    //
    //     // Your effect logic here
    // }, [reset, id, defaultValues, form, loadFunction, onFormCreated, postLoadFunction, preLoadFunction]);


    const onSubmit = async () => {
        showLoading()
        const data = getValues();
        data.company = companyId
        if (preSaveFunction) {
            const errorMsg = await preSaveFunction(data)
            if (errorMsg?.length > 0) {
                hideLoading()
                errorAlert(errorMsg)
                return
            }
        }
        return saveFunction(data).then(async savedModel => {
            if (savedModel) {
                if (preloadType) await preload.load(preloadType)
                if (postSaveFunction) {
                    postSaveFunction(savedModel)
                } else {
                    RouterUtils.goBack(navigate, savedModel)
                }
            }
            hideLoading()
        })
    };

    const onRemove = () => {
        confirmYes(async () => {
            showLoading()
            const data = getValues();
            data.company = companyId

            if (preSaveFunction) {
                const errorMsg = await preSaveFunction(data)
                if (errorMsg?.length > 0) {
                    hideLoading()
                    errorAlert(errorMsg)
                    return
                }
            }

            await removeFunction(data)
            RouterUtils.goBack(navigate)
            hideLoading()
        })
    }

    useEffect(() => {
        // console.log("FOOO", reset, id, defaultValues, form, loadFunction, onFormCreated, postLoadFunction, preLoadFunction)

        async function loadData() {
            // console.log("LOAD DATA")
            if (onFormCreated) await onFormCreated(form)
            if (preLoadFunction) await preLoadFunction(id)

            // console.log("IDDD", id, typeof id)
            if (id > 0 || typeof id === 'string') {
                showLoading()
                try {
                    const _model = await loadFunction(id)
                    postLoadFunction && postLoadFunction(_model)
                    reset(_model);
                } catch (e) {
                    console.error(e)
                    return
                } finally {
                    hideLoading()
                }
            } else {
                reset(defaultValues);
            }
            setLoading(false)
        }

        loadData().then(r => {
        })
    }, []) //eslint-disable-line
    // }, [reset, id, defaultValues, form, loadFunction, onFormCreated, postLoadFunction, preLoadFunction])

    if (loading) return <Spinner/>
    return (
        <div className="EditForm" style={style}>
            <div className="headline">
                <h1>{headlineI18n ? i18n(headlineI18n) : headline + ' '}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!_disabled && <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        {
                            !_disabled && removeFunction && <RemoveButton onClick={onRemove}/>
                        }
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>{initRightButtons && initRightButtons(form)}</div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>{initLeftFields && initLeftFields(form)}</div>
                    <div className='column'>{initCenterFields && initCenterFields(form)}</div>
                    <div className='column'>{initRightFields && initRightFields(form)}</div>
                </div>

                {initTabs &&
                    <TabView>
                        {initTabs(form)}
                    </TabView>
                }
            </form>
        </div>
    );
}

export default SimpleEditForm;

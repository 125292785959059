import React, {useState} from 'react';
import {MainButton} from "../../../components/Buttons/Button";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import useMountEffect from "../../../hooks/useMountEffect";
import useRoles from "../../../hooks/useRoles";
import useWindowSize from "../../../hooks/useWindowSize";
import {findMaxDate, findMinDate, isEmpty} from "../../../utils/ArrayUtil";
import {InvoiceingHistoryGrid} from "../../Invoices/edit/InvoiceHistoryDialog";
import DaylockService from "../../Timereg/DaylockService";
import DayplanningService from "../../Timereg/Dayplannings/DayplanningService";
import TimeregarticleService from "../../Timereg/TimeregarticleService";
import TimeregService from "../../Timereg/TimeregService";
import ProjectService from "../ProjectService";
import ProjectSummaryRentGrid from "./ProjectSummaryRentGrid";
import ProjectSummarySupplierinvoicesGrid from "./ProjectSummarySupplierinvoicesGrid";
import ProjectSummaryTimeregarticleGrid from "./ProjectSummaryTimeregarticleGrid";
import ProjectSummaryTimeregGrid from "./ProjectSummaryTimeregGrid";

const ProjectSummaryDialog = props => {
    const roles = useRoles()
    const projectId = props.projectId;
    const [project, setProject] = useState()
    const [dayplanningList, setDayplanningList] = useState()
    const [timeregList, setTimeregList] = useState()
    const [timeregarticleList, setTimeregarticleList] = useState()
    const [rentList, setRentList] = useState()
    const [daylockSet, setDaylockSet] = useState()

    const [windowWidth] = useWindowSize()
    const dialogWidth = Math.min(1600, windowWidth - 80)

    useMountEffect(async () => {
        async function load() {
            const [
                _project,
                _dayplanningList,
                _timeregList,
                _timeregarticleList,
                _rentList
            ] = await Promise.all([
                ProjectService.findById(projectId),
                DayplanningService.findByProject(projectId),
                TimeregService.findByProject(projectId),
                TimeregarticleService.findByProject(projectId),
                ProjectService.findRentsByProject(projectId)
            ])

            // console.log("_dayplanningList", _dayplanningList)
            if (!isEmpty(_dayplanningList)) {
                const startDate = findMinDate(_dayplanningList, 'date')
                const endDate = findMaxDate(_dayplanningList, 'date')
                const _daylockList = await DaylockService.findByDateInterval(startDate, endDate)
                setDaylockSet(new Set(_daylockList.map(item => item.date)))
            } else {
                setDaylockSet(new Set())
            }

            setProject(_project)
            setDayplanningList(_dayplanningList)
            setTimeregList(_timeregList)
            setTimeregarticleList(_timeregarticleList)
            setRentList(_rentList)
        }

        load()
    })

    if (!project) return <Spinner/>

    return <XlnzDialog headerI18n='project_summary'
                       onHide={props.onHide}
                       buttons={props => [<MainButton key='close' labelI18n='close' onClick={e => props.onHide()}/>]}
    >
        <div style={{width: dialogWidth + 'px', height: 'calc(100vh - 400px)'}}>
            <TabView>
                <TabPanel key='time_registration' headerI18n='time_registration'>
                    <ProjectSummaryTimeregGrid project={project} dayplanningList={dayplanningList}
                                               daylockSet={daylockSet} timeregList={timeregList}/>
                </TabPanel>
                <TabPanel key='articles' headerI18n='articles'>
                    <ProjectSummaryTimeregarticleGrid project={project} dayplanningList={dayplanningList}
                                                      daylockSet={daylockSet} timeregarticleList={timeregarticleList}/>
                </TabPanel>
                {
                    rentList?.length > 0 &&
                    <TabPanel key='rents' headerI18n='rents'>
                        <ProjectSummaryRentGrid project={project} rentList={rentList}/>
                    </TabPanel>
                }
                {
                    roles.hasRole(Role.SUPPLIERINVOICE_READ) &&
                    <TabPanel key='supplier_invoices' headerI18n='supplier_invoices'>
                        <ProjectSummarySupplierinvoicesGrid project={project}/>
                    </TabPanel>
                }
                {
                    !project.changeOrder && roles.hasRole(Role.INVOICE_READ) &&
                    <TabPanel key='invoicing_history' headerI18n='invoicing_history'>
                        <InvoiceingHistoryGrid name={project.name} projectId={projectId}/>
                    </TabPanel>
                }
            </TabView>
        </div>
    </XlnzDialog>
}
export default ProjectSummaryDialog;
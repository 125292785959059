import {i18n} from "../I18n/I18n";
import {findByField} from "../utils/ArrayUtil";

export default class AccessType {
    static get USER() {
        return Object.freeze({id: 'USER', name: i18n('user')});
    }

    static get EMPLOYEE() {
        return Object.freeze({id: 'EMPLOYEE', name: i18n('employee')});
    }

    static get CUSTOMERCONTACT() {
        return Object.freeze({id: 'CUSTOMERCONTACT', name: i18n('customer')});
    }

    static get SUPPLIERCONTACT() {
        return Object.freeze({id: 'SUPPLIERCONTACT', name: i18n('supplier')});
    }


    static values() {
        return [
            this.USER,
            this.EMPLOYEE,
            this.CUSTOMERCONTACT,
            this.SUPPLIERCONTACT
        ]
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }

    static getName(id) {
        if (!id) return ''
        return findByField(this.values(), 'id', id)?.name
    }
}
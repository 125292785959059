import React, {useState} from "react";
import {CancelButton, GenerateButton, SaveButton} from "../../../components/Buttons/Button";
import OptionalDatePicker from "../../../components/Date/OptionalDatePicker";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import RentType from "../../../enums/RentType";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import {i18n} from "../../../I18n/I18n";
import {deepCopy} from "../../../utils/ObjectUtils";
import TenderService from "../../Tenders/TenderService";
import ProjectService from "../ProjectService";

export const GenerateRentButton = props => {
    const {
        handleSubmit,
        saveProject,
        tender,
        dialog,
        onAfterGenerate,
        getValues
    } = props

    const showDialog = data => {
        showLoading();
        saveProject(data).then(_project => {
            if (_project) {
                const foo = <GenerateRentDialog
                    onAfterGenerate={p => {
                        onAfterGenerate(p)
                    }}
                    project={_project}
                    tender={tender}
                    // onHide={() => setShowGenerateRentDialog(false)}
                    getValues={getValues}
                    saveProject={saveProject}
                />

                if (dialog) dialog.show(foo)
                else renderOverlay(foo)

                hideLoading();
            } else {
                hideLoading();
            }
        })
    }

    return <div>
        <GenerateButton
            labelI18n='generate_rent'
            onClick={handleSubmit(showDialog)}
        />
    </div>
}

function isOkToGenerate(project) {
    if (!project.startDate) {
        errorAlert(i18n('error_project_start_date_missing'))
        return false
    }
    return true
}

export const GenerateRentDialog = props => {
    const {
        project,
        tender,
        onAfterGenerate,
        onHide,
        getValues,
        saveProject
    } = props

    useMountEffect(() => {
        setTimeout(() => {
            if (!isOkToGenerate(project)) onHide()
        }, 500)
    })

    const [tenderrentList, setTenderrentList] = useState(() => {
        if (!tender) return []
        const result = deepCopy(tender.rentList)
        result.forEach(item => item.checked = item.startDate !== undefined)
        return result
    })
    const [projectrentbasisList, setProjectrentbasisList] = useState(() => {
        const result = deepCopy(project.rentbasisList)
        result.forEach(item => item.checked = item.startDate !== undefined)
        return result
    })

    // const [selectAll, setSelectAll] = useState(false)

    const onSave = async () => {
        showLoading();
        if (tender) {
            tender.rentList = tenderrentList
            console.log("TENDER SAVE MODEL: ", tender)
            await TenderService.update(tender)
        }

        if (getValues) {
            const projectData = getValues()
            projectData.rentbasisList = projectrentbasisList
            await saveProject(projectData)
        } else {
            const saveModel = deepCopy(project)
            saveModel.rentbasisList = projectrentbasisList
            await ProjectService.update(saveModel)
        }

        const p = await ProjectService.generateRent(project.id)

        // console.log("PPPPPPPPPPP", p)

        onAfterGenerate(p)
        onHide()
        hideLoading();

    }

    const onSubmit = () => {
        onSave()
    }

    const getColumns = (list, setList) =>
        [
            {field: 'name', headerI18n: 'description'},
            {field: 'type', headerI18n: 'type', body: row => RentType.getName(row.type), width: 80},
            {
                field: 'quantity',
                headerI18n: 'quantity',
                body: GridTemplates.numberTwoDecimalsIfNeeded,
                align: 'right',
                width: 100
            },
            {
                field: 'aprice',
                headerI18n: 'aprice',
                body: GridTemplates.currencyTwoDecimals,
                align: 'right',
                width: 100
            },
            {
                field: 'discount',
                headerI18n: 'discount',
                body: GridTemplates.percentTimes100,
                align: 'right',
                width: 50
            },
            {
                field: 'price',
                headerI18n: 'price',
                body: GridTemplates.currencyTwoDecimals,
                align: 'right',
                footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
                width: 100
            },
            {
                field: 'startDate', headerI18n: 'start_date',
                body: row => <OptionalDatePicker value={row.startDate}
                                                 onChange={date => {
                                                     row.startDate = date
                                                     setList([...list])
                                                 }}
                                                 minDate={project.startDate}
                                                 showAddDays={true}
                />,
                rendererIsEditor: true,
                width: 140
            },
            {
                field: 'endDate', headerI18n: 'end_date',
                body: row => <OptionalDatePicker value={row.endDate}
                                                 onChange={date => {
                                                     row.endDate = date
                                                     setList([...list])
                                                 }}
                                                 minDate={project.startDate}
                                                 showAddDays={true}
                />,
                rendererIsEditor: true,
                width: 140
            },
        ]


    return <XlnzDialog header={i18n('generate_rent') + ': ' + project.name}
                       onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={onSubmit}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div style={{width: '1000px'}}>
            {
                tenderrentList.length > 0 &&
                <>
                    <h3>{i18n('tender') + ' - ' + i18n('rents')}</h3>
                    <Grid columns={getColumns(tenderrentList, setTenderrentList)}
                          value={tenderrentList}
                        // heightOffset={370}
                          scrollable={false}
                          rowClassName={e => ({'bold': e.headline})}
                          onRowSelect={() => {
                          }}
                    />
                </>
            }

            {
                projectrentbasisList.length > 0 &&
                <>
                    <h3>{i18n('project') + ' - ' + i18n('rental_basis')}</h3>
                    <Grid columns={getColumns(projectrentbasisList, setProjectrentbasisList)}
                          value={projectrentbasisList}
                          heightOffset={370}
                          scrollable={false}
                          rowClassName={e => ({'bold': e.headline})}
                          onRowSelect={() => {
                          }}
                    />
                </>
            }
        </div>
    </XlnzDialog>
}